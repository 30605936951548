import { render, staticRenderFns } from "./DefaultCourse.vue?vue&type=template&id=3462ef8e&scoped=true&"
import script from "./DefaultCourse.vue?vue&type=script&lang=js&"
export * from "./DefaultCourse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3462ef8e",
  null
  
)

export default component.exports