<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartLabels: {
      type: Array,
      required: true
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),

  mounted () {
    this.renderChart({
      labels: this.chartLabels,
      datasets: [
        {
          label: 'Inschrijvingen',
          borderColor: '#1ab394',
          pointBackgroundColor: '#1ab394',
          borderWidth: 1,
          pointBorderColor: '#1ab394',
          backgroundColor: 'transparent',
          data: this.chartData
        }
      ]
    }, this.options)
  }
}
</script>
