import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './modules/userInfo'
import componentData from '@/store/modules/componentData'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userInfo,
    componentData
  }
})
