<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-8">
      <h2>{{ this.$route.meta.pageTitle }}</h2>
      <b-breadcrumb :items="crumbs"></b-breadcrumb>
    </div>
    <div class="col-lg-4">
      <courses-component-btn-actions v-if="this.$route.meta.actionButton === 'courses.components'" />
    </div>
  </div>
</template>

<script>
import CoursesComponentBtnActions from '@/components/action-buttons/courses/components/BtnActions'

export default {
  name: 'BreadCrumb',
  components: { CoursesComponentBtnActions },
  computed: {
    crumbs: function () {
      const pathArray = this.$route.path.split('/')
      pathArray.shift()

      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (this.$route.matched[idx] !== undefined) {
          breadcrumbArray.push({
            path: path,
            href: breadcrumbArray[idx - 1] ? '/' + breadcrumbArray[idx - 1].path + '/' + path : '/' + path,
            disabled: this.$route.matched[idx].meta.breadCrumbDisabled,
            text: this.$route.matched[idx].meta.breadCrumb || path
          })
        }
        return breadcrumbArray
      }, [])
      breadcrumbs[breadcrumbs.length - 1].active = true
      return breadcrumbs
    }
  }
}
</script>

<style scoped>

</style>
