<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Titel"
        label-for="title"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="title"
          type="text"
          v-model="form.title"
          :state="formValidation.title"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
          Een titel is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Code"
        label-for="code"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="code"
          type="text"
          v-model="form.code"
          :state="formValidation.code"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.code" v-if="formValidation.code === false">
          Een code is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Maximum inschrijvingen"
        label-for="max_students"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="max_students"
          type="number"
          v-model="form.max_students"
          :state="formValidation.max_students"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.max_students" v-if="formValidation.max_students === false">
          Maximum inschrijvingen is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <hr class="hr-line-dashed" />
      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Inschrijvingsemail"
        label-for="subscription_email_id"
        label-align="left"
        label-align-sm="right"
      >
        <b-form-select
          id="subscription_email_id"
          type="text"
          :options="this.emails"
          v-model="form.subscription_email_id"
          :state="formValidation.subscription_email_id"
        ></b-form-select>
      </b-form-group>

      <hr class="hr-line-dashed" />
      <b-col sm="4" offset-sm="2">
        <b-button type="submit" variant="primary">Opslaan</b-button>
        <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
      </b-col>
    </b-form>
  </div>
</template>

<script>

import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Ilias',
  props: {
    course: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      emails: [{
        value: '',
        text: '----- Selecteer een email -----'
      }],
      form: {
        title: '',
        code: '',
        max_students: 500,
        subscription_email_id: ''
      },
      formValidation: {
        title: null,
        code: null,
        max_students: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('mailing/emails?company=' + this.$store.getters.companyId).then(response => {
      response.data.detail._embedded.emails.forEach((value, key) => {
        this.emails.push({
          text: value.title,
          value: value.id
        })
      })
    }).catch(err => {
      console.log(err)
    })

    if (this.course) {
      this.form.title = this.course.title
      this.form.code = this.course.code
      this.form.max_students = this.course.max_students
      this.form.subscription_email_id = this.course.subscription_email_id
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.title = this.form.title !== ''
      this.formValidation.code = this.form.code !== ''
      this.formValidation.max_students = this.form.max_students !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        this.$emit('formSubmit', this.form)
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = ''
      this.form.code = ''
      this.form.max_students = 500
      this.form.subscription_email_id = ''
    },
    resetErrors () {
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
