<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlankLayout',
  mounted () {
    document.body.classList.add('gray-bg')
  }
}
</script>

<style scoped>

</style>
