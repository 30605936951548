<template>
  <div>Logout</div>
</template>

<script>
import { mapActions } from 'vuex'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: 'Logout',
  beforeMount () {
    this.clear()
    authToken.clear()

    this.$router.push('/')
  },
  methods: {
    ...mapActions([
      'clear'
    ])
  }
}
</script>

<style scoped>

</style>
