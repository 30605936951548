import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/modules/auth/Login.vue'
import Unauthorized from '@/views/modules/error/Unauthorized'
import PageNotFound from '@/views/modules/error/PageNotFound'
import AuthToken from '@/modules/AuthToken'
import store from '@/store'
import Logout from '@/views/modules/auth/Logout'
import Confirmation from '@/views/modules/account/Confirmation'

import courses from '@/router/courses'
import userManagement from '@/router/userManagement'
import mailing from '@/router/mailing'
import companies from '@/router/companies'

Vue.use(VueRouter)

const authTokenService = new AuthToken()

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      isAuthenticated: true,
      layout: 'blank'
    }
  },
  {
    path: '/account-confirmation/:id',
    name: 'AccountConfirmation',
    component: Confirmation,
    meta: {
      isAuthenticated: false,
      layout: 'blank'
    }
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/modules/auth/ForgotPassword'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      isAuthenticated: true,
      layout: 'dashboard'
    },
    component: () => import('../views/modules/dashboard/Dashboard')
  },
  userManagement,
  courses,
  mailing,
  companies
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== 'unauthorized') {
    authTokenService.get().then((res) => {
      if (to.matched.some(record => record.meta.isAuthenticated)) {
        if (!res) {
          next({
            path: '/unauthorized',
            params: { nextUrl: to.fullPath }
          })
        } else {
          store.dispatch('fetch').then((res) => {
            const userGrants = store.getters.grants

            if (to.meta.grants !== undefined) {
              let granted = false

              for (let i = 0; i < to.meta.grants.length; i++) {
                if (userGrants.indexOf(to.meta.grants[i]) > -1) {
                  granted = true
                }
              }

              if (!granted) {
                next({
                  path: '/unauthorized',
                  params: { nextUrl: to.fullPath }
                })
              }
            }

            next()
          }).catch((err) => {
            console.error(err)
          })
        }
      } else {
        next()
      }
    }).catch(() => {
      next({
        path: '/unauthorized',
        params: { nextUrl: to.fullPath }
      })
    })
  }
})

export default router
