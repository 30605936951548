<template>
  <div class="middle-box text-center animated fadeInDown">
    <h1>404</h1>
    <h3 class="font-bold">Pagina niet gevonden</h3>

    <div class="error-desc">
      Sorry, de pagina die je hebt opgevraagd werd niet gevonden.
      <div class="form-group mt-3">
        <a href="/" class="btn btn-primary">Terug naar begin</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  mounted () {
    document.body.classList.add('gray-bg')
  }
}
</script>

<style scoped>

</style>
