import Courses from '@/views/modules/courses/Courses'
import CoursesCourses from '@/views/modules/courses/courses/Courses'
import CoursesCreate from '@/views/modules/courses/courses/Create'
import CoursesRead from '@/views/modules/courses/courses/Read'
import CoursesUpdate from '@/views/modules/courses/courses/Update'
import CourseCharts from '@/views/modules/courses/courses/Charts'

import CourseTypesRead from '@/views/modules/courses/types/Read'
import CourseTypesCreate from '@/views/modules/courses/types/Create'
import CourseTypesUpdate from '@/views/modules/courses/types/Update'
import Types from '@/views/modules/courses/types/Types'
import Ilias from '@/views/modules/courses/courses/Ilias'

import Components from '@/views/modules/courses/components/Components'
import ComponentsRead from '@/views/modules/courses/components/Read'
import ComponentsCreate from '@/views/modules/courses/components/Create'
import ComponentsUpdate from '@/views/modules/courses/components/Update'
import ComponentCourses from '@/views/modules/courses/components/Courses'
import ComponentDefaultCourse from '@/views/modules/courses/components/DefaultCourse'
import ComponentCoursesUpdate from '@/views/modules/courses/components/CoursesUpdate'
import ComponentDefaultCourseUpdate from '@/views/modules/courses/components/DefaultCourseUpdate'
import ComponentFinish from '@/views/modules/courses/components/Finish'

const routes = {
  path: '/courses/',
  component: Courses,
  meta: {
    breadCrumb: 'Opleidingen',
    breadCrumbDisabled: true,
    grants: ['courses.crud']
  },
  children: [
    {
      path: 'courses',
      component: CoursesCourses,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Lijst opleidingen',
        pageTitle: 'Lijst opleidingen',
        grants: ['courses.crud']
      },
      children: [
        {
          path: 'create/',
          name: 'CoursesCreate',
          component: CoursesCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleiding toevoegen',
            pageTitle: 'Opleiding toevoegen',
            grants: ['courses.crud']
          }
        },
        {
          path: 'charts/:id',
          name: 'CourseCharts',
          component: CourseCharts,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Statistieken',
            pageTitle: 'Opleiding statistieken',
            grants: ['courses.crud']
          }
        },
        {
          path: '',
          name: 'CoursesRead',
          component: CoursesRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Lijst opleidingen',
            pageTitle: 'Lijst opleidingen',
            grants: ['courses.crud']
          }
        },
        {
          path: ':id',
          name: 'CoursesUpdate',
          component: CoursesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleiding bewerken',
            pageTitle: 'Opleiding bewerken',
            grants: ['courses.crud']
          }
        },
        {
          path: 'ilias/:id',
          name: 'IliasImport',
          component: Ilias,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Ilias logins',
            pageTitle: 'Ilias logins',
            grants: ['courses.ilias.crud']
          }
        }
      ]
    },
    {
      path: 'types',
      component: Types,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Opleiding types',
        pageTitle: 'Opleiding types',
        grants: ['courses.types.crud']
      },
      children: [
        {
          path: '',
          name: 'CourseTypesRead',
          component: CourseTypesRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleidingen types',
            pageTitle: 'Opleidingen types',
            grants: ['courses.types.crud']
          }
        },
        {
          path: 'create/',
          name: 'CoursesTypesCreate',
          component: CourseTypesCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleidingstype toevoegen',
            pageTitle: 'Opleidingstype toevoegen',
            grants: ['courses.types.crud']
          }
        },
        {
          path: ':id',
          name: 'CourseTypesUpdate',
          component: CourseTypesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleidingstype bewerken',
            pageTitle: 'Opleidingstype bewerken',
            grants: ['courses.types.crud']
          }
        }
      ]
    },
    {
      path: 'components',
      component: Components,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Web componenten',
        pageTitle: 'Web componenten',
        grants: ['courses.crud']
      },
      children: [
        {
          path: '',
          name: 'ComponentsRead',
          component: ComponentsRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Web componenten',
            pageTitle: 'Web componenten',
            grants: ['courses.components.crud']
          }
        },
        {
          path: 'create/',
          name: 'ComponentsCreate',
          component: ComponentsCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Web component toevoegen',
            pageTitle: 'Web component toevoegen',
            grants: ['courses.components.crud']
          }
        },
        {
          path: 'courses/',
          name: 'ComponentCourses',
          component: ComponentCourses,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleidingen',
            pageTitle: 'Web compontent opleidingen',
            grants: ['courses.components.crud']
          }
        },
        {
          path: 'courses-update/:id',
          name: 'ComponentCoursesUpdate',
          component: ComponentCoursesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Opleidingen',
            pageTitle: 'Web compontent opleidingen',
            grants: ['courses.components.crud'],
            actionButton: 'courses.components.crud'
          }
        },
        {
          path: 'default-course/',
          name: 'ComponentDefaultCourse',
          component: ComponentDefaultCourse,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Standaard opleiding',
            pageTitle: 'Web compontent standaard opleiding',
            grants: ['courses.components.crud']
          }
        },
        {
          path: 'finish/:id',
          name: 'ComponentFinish',
          component: ComponentFinish,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Afwerken',
            pageTitle: 'Web compontent afwerken',
            grants: ['courses.components.crud']
          }
        },
        {
          path: 'default-course-update/:id',
          name: 'ComponentDefaultCourseUpdate',
          component: ComponentDefaultCourseUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Standaard opleiding',
            pageTitle: 'Web compontent standaard opleiding',
            grants: ['courses.crud'],
            actionButton: 'courses.components.crud'
          }
        },
        {
          path: ':id',
          name: 'ComponentsUpdate',
          component: ComponentsUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Web component bewerken',
            pageTitle: 'Web compontent bewerken',
            grants: ['courses.crud'],
            actionButton: 'courses.components.crud'
          }
        }
      ]
    }
  ]
}

export default routes
