<template>
  <div id="wrapper">
    <side-bar />
    <div id="page-wrapper" class="gray-bg dashbard-1">
      <top-bar />
      <bread-crumb />
      <div class="wrapper wrapper-content animated fadeInRight">
        <div class="row">
          <slot />
        </div>
      </div>
      <div class="footer">
        <div>
          <strong>Copyright</strong> Educam Partner &copy; {{ (year > 2020) ? '2020 - ' + year : year }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import TopBar from '@/components/TopBar'
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'DashboardLayout',
  components: { BreadCrumb, TopBar, SideBar },
  mounted () {
    document.body.classList.remove('gray-bg')

    if (window.innerWidth < 769) {
      console.log('small screen')
      document.body.classList.add('body-small')
    } else {
      console.log('large screen')
      document.body.classList.remove('body-small')
    }
  },
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>
