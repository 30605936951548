<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Bedrijfstype bwerken <small>Vul alle velden in om het bedrijfstype te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>Het bedrijfstype is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Type"
            label-for="type"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="type"
              type="text"
              v-model="form.type"
              :state="formValidation.type"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.type" v-if="formValidation.type === false">
              Een type is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van het bedrijfstype. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'CompanyTypesUpdate',
  data () {
    return {
      showError: false,
      showSuccess: false,
      companyType: {},
      form: {
        type: ''
      },
      formValidation: {
        type: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/crm/company-types/' + this.$route.params.id).then(response => {
      this.companyType = response.data.detail
      this.form.type = this.companyType.type
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.showSuccess = false

      this.resetErrors()

      this.formValidation.type = this.form.type !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        EducamApi.put('/crm/company-types/' + this.$route.params.id, {
          type: this.form.type
        }).then(response => {
          this.showSuccess = true

          setTimeout(() => {
            this.showSuccess = false
          }, 5000)
        }).catch(err => {
          this.showError = true
          console.log(err.response)
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.type = this.company.type
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
