import UserManagement from '@/views/modules/user-management/UserManagement'
import UserProfile from '@/views/modules/user-management/profile/Profile'
import UserPassword from '@/views/modules/user-management/profile/Password'
import Grants from '@/views/modules/user-management/grants/Grants'
import Create from '@/views/modules/user-management/grants/Create'
import Read from '@/views/modules/user-management/grants/Read'
import Update from '@/views/modules/user-management/grants/Update'
import UserRoles from '@/views/modules/user-management/user-roles/UserRoles'
import UserRolesCreate from '@/views/modules/user-management/user-roles/Create'
import UserRolesRead from '@/views/modules/user-management/user-roles/Read'
import UserRolesUpdate from '@/views/modules/user-management/user-roles/Update'
import Users from '@/views/modules/user-management/users/Users'
import UsersCreate from '@/views/modules/user-management/users/Create'
import UsersRead from '@/views/modules/user-management/users/Read'
import UsersUpdate from '@/views/modules/user-management/users/Update'

const routes = {
  path: '/user-management/',
  name: 'UserManagement',
  component: UserManagement,
  meta: {
    breadCrumb: 'Gebruikersbeheer',
    breadCrumbDisabled: true
  },
  children: [
    {
      path: 'profile/',
      name: 'UserProfile',
      component: UserProfile,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Profiel',
        pageTitle: 'Profiel'
      }
    },
    {
      path: 'password/',
      name: 'UserPassword',
      component: UserPassword,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Wachtwoord bewerken',
        pageTitle: 'Wachtwoord bewerken'
      }
    },
    {
      path: 'grants/',
      component: Grants,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Grant types',
        pageTitle: 'Grant types',
        grants: ['grants.crud']
      },
      children: [
        {
          path: 'create/',
          name: 'GrantsCreate',
          component: Create,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Grant type toevoegen',
            pageTitle: 'Grant type toevoegen',
            grants: ['grants.crud']
          }
        },
        {
          path: '',
          name: 'GrantsRead',
          component: Read,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            pageTitle: 'Grant types',
            grants: ['grants.crud']
          }
        },
        {
          path: ':id',
          name: 'GrantsUpdate',
          component: Update,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Grant type bewerken',
            pageTitle: 'Grant type bewerken',
            grants: ['grants.crud']
          }
        }
      ]
    },
    {
      path: 'user-roles/',
      component: UserRoles,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Gebruikersrollen',
        pageTitle: 'Gebruikersrollen',
        grants: ['userroles.crud']
      },
      children: [
        {
          path: 'create/',
          name: 'UserRolesCreate',
          component: UserRolesCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Gebruikersrol toevoegen',
            pageTitle: 'Gebruikersrol toevoegen',
            grants: ['userroles.crud']
          }
        },
        {
          path: '',
          name: 'UserRolesRead',
          component: UserRolesRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            pageTitle: 'Gebruikersrollen',
            grants: ['userroles.crud']
          }
        },
        {
          path: ':id',
          name: 'UserRolesUpdate',
          component: UserRolesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Gebruikersrol bewerken',
            pageTitle: 'Gebruikersrol bewerken',
            grants: ['userroles.crud']
          }
        }
      ]
    },
    {
      path: 'users/',
      component: Users,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Gebruikers',
        pageTitle: 'Gebruikers',
        grants: ['users.crud']
      },
      children: [
        {
          path: 'create/',
          name: 'UsersCreate',
          component: UsersCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Gebruiker toevoegen',
            pageTitle: 'Gebruiker toevoegen',
            grants: ['users.crud']
          }
        },
        {
          path: '',
          name: 'UsersRead',
          component: UsersRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            pageTitle: 'Gebruikers',
            grants: ['users.crud']
          }
        },
        {
          path: ':id',
          name: 'UsersUpdate',
          component: UsersUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Gebruiker bewerken',
            pageTitle: 'Gebruiker bewerken',
            grants: ['users.crud']
          }
        }
      ]
    }
  ]
}

export default routes
