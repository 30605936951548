<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Opleidingen selecteren</h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>De web component is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-for="courses"
            label-align="left"
            label-class="required"
          >
            <v-multiselect-listbox
              :state="formValidation.courses"
              v-model="form.courses"
              :options="courses"
              :reduce-display-property="(option) => option.label"
              :reduce-value-property="(option) => option.code"
            ></v-multiselect-listbox>
            <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
              Een titel is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="!this.formValidation.courses">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Je moet verplicht minstens één opleiding selecteren</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'CoursesUpdate',
  data () {
    return {
      component: null,
      courses: [],
      showError: false,
      showSuccess: false,
      form: {
        courses: []
      },
      formValidation: {
        courses: true
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.courses = this.form.courses.length > 0

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        this.showSuccess = true
      }

      const selectedCourses = []

      this.form.courses.forEach((value, key) => {
        selectedCourses.push(value.code)
      })

      EducamApi.put('/courses/components/' + this.$route.params.id, {
        title: this.component.title,
        conditions_url: this.component.conditions_url,
        css_btn_color: this.component.css_btn_color,
        css_btn_bg_color: this.component.css_btn_bg_color,
        css_font_family: this.component.css_font_family,
        css_font_color: this.component.css_font_color,
        default_course: this.component.default_course,
        success_message_nl: this.component.success_message_nl,
        success_message_fr: this.component.success_message_fr,
        courses: selectedCourses,
        companies_id: this.$store.getters.companyId,
        course_types_id: this.component.course_types_id
      }).then(response => {
        this.showSuccess = true

        setTimeout(() => {
          this.showSuccess = false
        }, 5000)
      }).catch(err => {
        this.showError = true
        console.log(err.response)
      })
    },
    onReset (event) {
      event.preventDefault()
      this.form.courses = []
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  },
  beforeCreate () {
    EducamApi.get('/courses/components/' + this.$route.params.id).then(response => {
      this.component = response.data.detail

      response.data.detail.courses.forEach((value, key) => {
        this.form.courses.push({
          label: value.title + ' (' + value.code + ')',
          code: value.id
        })
      })

      const suffix = '?company=' + this.$store.getters.companyId + '&sort=desc&type=' + this.component.course_types_id
      EducamApi.get('/courses' + suffix).then((response) => {
        if (response.data.detail.total_items > 0) {
          response.data.detail._embedded.courses.forEach((course, key) => {
            const item = {
              label: course.title + ' (' + course.code + ')',
              code: course.id
            }
            this.courses.push(item)
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
