import Mailing from '@/views/modules/mailing/Mailing'
import Templates from '@/views/modules/mailing/templates/Templates'
import TemplatesRead from '@/views/modules/mailing/templates/Read'
import TemplatesCreate from '@/views/modules/mailing/templates/Create'
import TemplatesUpdate from '@/views/modules/mailing/templates/Update'
import Emails from '@/views/modules/mailing/emails/Emails'
import EmailsRead from '@/views/modules/mailing/emails/Read'
import EmailsCreate from '@/views/modules/mailing/emails/Create'
import EmailsUpdate from '@/views/modules/mailing/emails/Update'
import Actions from '@/views/modules/mailing/actions/Actions'
import ActionsRead from '@/views/modules/mailing/actions/Read'
import ActionsCreate from '@/views/modules/mailing/actions/Create'
import ActionsUpdate from '@/views/modules/mailing/actions/Update'

const routes = {
  path: '/mailing/',
  name: 'Mailing',
  component: Mailing,
  meta: {
    breadCrumb: 'Mailing',
    breadCrumbDisabled: true,
    grants: ['mailing.templates.crud']
  },
  children: [
    {
      path: 'templates/',
      component: Templates,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Email templates',
        pageTitle: 'Email templates',
        grants: ['mailing.templates.crud']
      },
      children: [
        {
          path: '',
          name: 'TemplatesRead',
          component: TemplatesRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Email templates',
            pageTitle: 'Email templates',
            grants: ['mailing.templates.crud']
          }
        },
        {
          path: 'create/',
          name: 'TemplatesCreate',
          component: TemplatesCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Template toevoegen',
            pageTitle: 'Template toevoegen',
            grants: ['mailing.templates.crud']
          }
        },
        {
          path: ':id',
          name: 'TemplatesUpdate',
          component: TemplatesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Template bewerken',
            pageTitle: 'Template bewerken',
            grants: ['mailing.templates.crud']
          }
        }
      ]
    },
    {
      path: 'emails/',
      component: Emails,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Emails',
        pageTitle: 'Emails',
        grants: ['mailing.emails.crud']
      },
      children: [
        {
          path: '',
          name: 'EmailsRead',
          component: EmailsRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Emails',
            pageTitle: 'Emails',
            grants: ['mailing.emails.crud']
          }
        },
        {
          path: 'create/',
          name: 'EmailsCreate',
          component: EmailsCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Email toevoegen',
            pageTitle: 'Email toevoegen',
            grants: ['mailing.templates.crud']
          }
        },
        {
          path: ':id',
          name: 'EmailsUpdate',
          component: EmailsUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Email bewerken',
            pageTitle: 'Email bewerken',
            grants: ['mailing.templates.crud']
          }
        }
      ]
    },
    {
      path: 'actions/',
      component: Actions,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Acties',
        pageTitle: 'Acties',
        grants: ['mailing.actions.crud']
      },
      children: [
        {
          path: '',
          name: 'ActionsRead',
          component: ActionsRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Acties',
            pageTitle: 'Acties',
            grants: ['mailing.actions.crud']
          }
        },
        {
          path: 'create/',
          name: 'ActionsCreate',
          component: ActionsCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Actie toevoegen',
            pageTitle: 'Actie toevoegen',
            grants: ['mailing.actions.crud']
          }
        },
        {
          path: ':id',
          name: 'ActionsUpdate',
          component: ActionsUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Actie bewerken',
            pageTitle: 'Actie bewerken',
            grants: ['mailing.actions.crud']
          }
        }
      ]
    }
  ]
}

export default routes
