<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Profiel bewerken <small>Vul alle velden in om uw profiel te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>Uw profiel is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Voornaam"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="first_name"
              type="text"
              v-model="form.first_name"
              :state="formValidation.first_name"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.first_name">
              Voornaam is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Naam"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="last_name"
              type="text"
              v-model="form.last_name"
              :state="formValidation.last_name"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.last_name">
              Naam is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Email"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="email"
              type="text"
              v-model="form.email"
              :state="formValidation.email && formValidation.emailExist"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.email" v-if="formValidation.email === false">
              Email is verplicht
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="formValidation.emailExist" v-if="formValidation.emailExist === false">
              Het opgegeven email adres is reeds bekend in het systeem
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van uw profiel. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      showSuccess: false,
      showError: false,
      user: {},
      form: {
        first_name: '',
        last_name: '',
        email: ''
      },
      formValidation: {
        first_name: null,
        last_name: null,
        email: null,
        emailExist: null
      }
    }
  },
  mounted () {
    this.form.first_name = this.$store.getters.firstName
    this.form.last_name = this.$store.getters.lastName
    this.form.email = this.$store.getters.email
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.showSuccess = false
      this.resetErrors()

      this.formValidation.first_name = this.form.first_name !== ''
      this.formValidation.last_name = this.form.last_name !== ''
      this.formValidation.email = this.form.email !== ''

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        EducamApi.get('/local/users?uuid=' + this.$store.getters.uuid).then(response => {
          EducamApi.put('/local/users/' + response.data.id, {
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            email: this.form.email
          }).then(response => {
            this.showSuccess = true
            this.formValidation.emailExist = true

            setTimeout(() => {
              this.showSuccess = false
            }, 5000)
          }).catch(err => {
            this.showError = true
            if (err.response.data.validation_messages.email.length > 0) {
              this.formValidation.emailExist = false
            } else {
              this.formValidation.emailExist = true
            }
          })
        }).catch(err => {
          console.log(err)
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.first_name = this.$store.getters.firstName
      this.form.last_name = this.$store.getters.lastName
      this.form.email = this.$store.getters.email
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
