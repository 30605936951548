<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="dropdown profile-element">
            <img alt="image" class="rounded-circle" :src="avatar + userInfo.firstName + '+' + userInfo.lastName"/><br />
            <b-dropdown class="dropdown-toggle profile-menu" variant="link" no-caret>
              <template #button-content>
                <span class="block m-t-xs font-bold">{{ userInfo.firstName + ' ' + userInfo.lastName }}</span>
                <span class="text-muted text-xs block">{{ (userInfo.userRole !== null) ? userInfo.userRole.title : null }} <b class="caret"></b></span>
              </template>
              <b-dropdown-item :to="{ name: 'UserProfile' }">Profiel</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'UserPassword' }">Wachtwoord bewerken</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item to="/logout">Afmelden</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="logo-element">
            IN+
          </div>
        </li>
        <li v-bind:class="routeMatched('/user-management') ? 'active mm-active' : null">
          <router-link to="/user-management" event="" v-if="this.hasAccess(['grants.crud', 'userroles.crud', 'users.crud'], 'single')"><i class="fa fa-users"></i> <span class="nav-label">Gebruikersbeheer</span> <span class="fa arrow"></span></router-link>
          <ul class="nav nav-second-level">
            <li v-if="this.hasAccess(['grants.crud'])" v-bind:class="routeMatched('/user-management/grants') ? 'active' : null"><router-link to="/user-management/grants">Grant types</router-link></li>
            <li v-if="this.hasAccess(['userroles.crud'])" v-bind:class="routeMatched('/user-management/user-roles') ? 'active' : null"><router-link to="/user-management/user-roles">Gebruikersrollen</router-link></li>
            <li v-if="this.hasAccess(['users.crud'])" v-bind:class="routeMatched('/user-management/users') ? 'active' : null"><router-link to="/user-management/users">Gebruikers</router-link></li>
          </ul>
        </li>
        <li v-bind:class="routeMatched('/companies') ? 'active mm-active' : null">
          <router-link to="/companies/companies" event="" v-if="this.hasAccess(['crm.companies.crud', 'crm.types.crud'], 'single')"><i class="fa fa-building"></i> <span class="nav-label">Bedrijven</span> <span class="fa arrow"></span></router-link>
          <ul class="nav nav-second-level">
            <li v-if="this.hasAccess(['crm.companies.types.crud'])" v-bind:class="routeMatched('/companies/company-types') ? 'active' : null"><router-link to="/companies/company-types">Bedrijfstypes</router-link></li>
            <li v-if="this.hasAccess(['crm.companies.crud'])" v-bind:class="routeMatched('/companies/companies') ? 'active' : null"><router-link to="/companies/companies">Bedrijven</router-link></li>
          </ul>
        </li>
        <li v-bind:class="routeMatched('/mailing') ? 'active mm-active' : null">
          <router-link to="/mailing" event="" v-if="this.hasAccess(['mailing.templates.crud', 'mailing.emails.crud'], 'single')"><i class="fa fa-envelope"></i> <span class="nav-label">Mailing</span> <span class="fa arrow"></span></router-link>
          <ul class="nav nav-second-level">
            <li v-if="this.hasAccess(['mailing.actions.crud'])" v-bind:class="routeMatched('/mailing/actions') ? 'active' : null"><router-link to="/mailing/actions">Email acties</router-link></li>
            <li v-if="this.hasAccess(['mailing.templates.crud'])" v-bind:class="routeMatched('/mailing/templates') ? 'active' : null"><router-link to="/mailing/templates">Email templates</router-link></li>
            <li v-if="this.hasAccess(['mailing.emails.crud'])" v-bind:class="routeMatched('/mailing/emails') ? 'active' : null"><router-link to="/mailing/emails">Systeem emails</router-link></li>
          </ul>
        </li>
        <li v-bind:class="routeMatched('/courses') ? 'active mm-active' : null">
          <router-link to="/courses/courses" event="" v-if="this.hasAccess(['courses.crud', 'courses.types.crud', 'courses.components.crud'], 'single')"><i class="fa fa-book"></i> <span class="nav-label">Opleidingen</span> <span class="fa arrow"></span></router-link>
          <ul class="nav nav-second-level">
            <li v-if="this.hasAccess(['courses.types.crud'])" v-bind:class="routeMatched('/courses/types') ? 'active' : null"><router-link to="/courses/types">Opleiding types</router-link></li>
            <li v-if="this.hasAccess(['courses.crud'])" v-bind:class="routeMatched('/courses/courses') ? 'active' : null"><router-link to="/courses/courses">Lijst opleidingen</router-link></li>
            <li v-if="this.hasAccess(['courses.crud'])" v-bind:class="routeMatched('/courses/components') ? 'active' : null"><router-link to="/courses/components">Web componenten</router-link></li>
          </ul>
        </li>
      </ul>

    </div>
  </nav>
</template>

<script>

import { mapGetters } from 'vuex'
import $ from 'jquery'
import Access from '@/mixins/Access'
require('metismenu')

export default {
  name: 'SideBar',
  mixins: [Access],
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      avatar: 'https://eu.ui-avatars.com/api/?background=1ab394&color=fff&name='
    }
  },
  mounted () {
    $('#side-menu').metisMenu()
  },
  methods: {
    routeMatched (route) {
      if (this.$route.matched.length > 0) {
        for (var i = 0; i < this.$route.matched.length; i++) {
          if (route === this.$route.matched[i].path) {
            return true
          }
        }
      }
      return false
    }
  }
}
</script>
<style scoped>
#educamLogo {
  padding:30px;
  position:absolute;
  bottom:20px;
}
</style>
