import AuthToken from '@/modules/AuthToken'
import axios from 'axios'

const authToken = new AuthToken()

const state = {
  uuid: null,
  email: null,
  firstName: null,
  lastName: null,
  userRole: null,
  grants: null,
  companyId: null
}

const mutations = {
  'UPDATE_INFO' (state, value) {
    state.uuid = value.uuid
    state.email = value.email
    state.firstName = value.first_name
    state.lastName = value.last_name
    state.userRole = value._embedded.userRole
    state.grants = value.userGrants
    state.companyId = value.companies_id
  },
  'CLEAR_INFO' (state) {
    state.uuid = null
    state.email = null
    state.firstName = null
    state.lastName = null
    state.userRole = null
    state.grants = null
    state.companyId = null
  }
}

const actions = {
  async fetch ({ commit }, body) {
    const token = await authToken.get()

    return new Promise((resolve, reject) => {
      if (token) {
        return axios.get(process.env.VUE_APP_API_URL + '/identity', {
          headers: {
            Authorization: `Bearer ${token.access_token}`
          }
        }).then((res) => {
          const userGrants = []

          if (res.data._embedded.userRole !== undefined) {
            for (let i = 0; i < res.data._embedded.userRole.grants.length; i++) {
              userGrants.push(res.data._embedded.userRole.grants[i].title)
            }
          }

          res.data.userGrants = userGrants
          commit('UPDATE_INFO', res.data)
          resolve()
        }).catch((err) => {
          console.error(err)
          resolve()
        })
      }
      resolve()
    })
  },
  clear ({ commit }, bldy) {
    return new Promise((resolve) => {
      commit('CLEAR_INFO')
      resolve()
    })
  }
}

const getters = {
  uuid (state) {
    return state.uuid
  },
  email (state) {
    return state.email
  },
  firstName (state) {
    return state.firstName
  },
  lastName (state) {
    return state.lastName
  },
  userRole (state) {
    return state.userRole
  },
  grants (state) {
    return state.grants
  },
  companyId (state) {
    return state.companyId
  },
  userInfo (state) {
    return {
      uuid: state.uuid,
      email: state.email,
      firstName: state.firstName,
      lastName: state.lastName,
      userRole: state.userRole,
      grants: state.grants,
      companyId: state.companies_id
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
