<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Grant type toevoegen <small>Vul alle velden in om het grant type te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>Het grant type is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Titel"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="title"
              type="text"
              v-model="form.title"
              :state="formValidation.title && formValidation.titleExist"
              placeholder="Voor een titel in voor het grant type"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
              Een titel is verplicht om het grant type te kunnen bewerken
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="formValidation.titleExist" v-if="formValidation.titleExist === false">
              Het opgegeven grant type bestaat reeds
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van het grant type. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Update',
  data () {
    return {
      showSuccess: false,
      grantType: {},
      showError: false,
      form: {
        title: ''
      },
      formValidation: {
        title: null,
        titleExist: null
      }
    }
  },
  beforeCreate () {
    EducamApi.get('/local/grants/' + this.$route.params.id).then(response => {
      this.grantType = response.data
      this.form.title = response.data.title
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.showSuccess = false
      this.resetErrors()

      this.formValidation.title = this.form.title !== ''

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        EducamApi.put('/local/grants/' + this.$route.params.id, {
          title: this.form.title
        }).then(response => {
          this.showSuccess = true

          setTimeout(() => {
            this.showSuccess = false
          }, 5000)
        }).catch(err => {
          this.showError = true
          if (err.response.data.validation_messages.title !== undefined && err.response.data.validation_messages.title.length > 0) {
            this.formValidation.titleExist = false
          }
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = this.grantType.title
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
