<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Email acties</h5>
        <div class="ibox-tools">
          <router-link :to="{name: 'ActionsCreate'}" class="btn btn-primary btn-xs">Email actie toevoegen</router-link>
        </div>
      </div>
      <div class="ibox-content">
        <b-table
          striped
          hover
          :items="actions"
          :fields="fields"
          show-empty
        >
          <template v-slot:empty>
            <b-row>
              <b-col cols="12">
                <h4 class="text-center text-muted">
                  Er zijn geen acties om weer te geven
                </h4>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(id)="row">
            {{ row.item.id }}
          </template>
          <template v-slot:cell(name)="row">
            {{ row.item.name }}
          </template>
          <template v-slot:cell(actions)="row">
            <div class="ibox-links">
              <b-link :to="{name: 'ActionsUpdate', params: {id: row.item.id}}" class="btn btn-white btn-bitbucket" ><i class="fa fa-wrench text-info"></i></b-link>
              <b-link  class="btn btn-white btn-bitbucket" @click="showDeleteModal(row.item.id, row.item.name)"><i class="fa fa-trash text-danger"></i></b-link>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Read',
  data () {
    return {
      actions: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Naam', sortable: true },
        { key: 'actions', label: 'Acties', sortable: false }
      ]
    }
  },
  methods: {
    showDeleteModal (actionId, actionTitle) {
      const h = this.$createElement
      const messageVNode = h('p', {}, ['Je staat op het punt de actie ', h('strong', {}, actionTitle), ' te verwijderen.', h('br'), ' Weet je zeker dat je wil doorgaan?'])
      const buttonVNode = h('span', {}, ['Verwijderen ', h('i', { class: 'fa fa-trash' })])
      this.$bvModal.msgBoxConfirm(messageVNode, {
        title: 'Actie verwijderen',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: buttonVNode,
        cancelTitle: 'Annuleren',
        hideHeaderClose: true,
        centered: true
      }).then(async value => {
        if (value === true) {
          await EducamApi.delete('/mailing/actions/' + actionId)
          EducamApi.get('/mailing/actions').then((data) => {
            if (data.data.detail.total_items > 0) {
              this.actions = data.data.detail._embedded.actions
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    EducamApi.get('/mailing/actions').then((data) => {
      if (data.data.detail.total_items > 0) {
        this.actions = data.data.detail._embedded.actions
      }
    }).catch((err) => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
