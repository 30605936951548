<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Gebruiker toevoegen <small>Vul alle velden in om een gebruiker toe te voegen</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Voornaam"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="first_name"
              type="text"
              v-model="form.first_name"
              :state="formValidation.first_name"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.first_name">
              Voornaam is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Naam"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="last_name"
              type="text"
              v-model="form.last_name"
              :state="formValidation.last_name"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.last_name">
              Naam is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Email"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="email"
              type="text"
              v-model="form.email"
              :state="formValidation.email && formValidation.emailExist"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.email" v-if="formValidation.email === false">
              Email is verplicht
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="formValidation.emailExist" v-if="formValidation.emailExist === false">
              Het opgegeven email adres is reeds bekend in het systeem
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Bedrijf"
            label-for="companies_id"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-select
              id="companies_id"
              v-model="form.companies_id"
              :options="companies"
              :state="formValidation.companies_id"
            ></b-form-select>
            <b-form-invalid-feedback :state="formValidation.companies_id">
              Bedrijf is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Gebruikersrol"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-select
              id="user_roles_id"
              v-model="form.user_roles_id"
              :options="userRoles"
              :state="formValidation.user_roles_id"
            ></b-form-select>
            <b-form-invalid-feedback :state="formValidation.user_roles_id">
              Gebruikersrol is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de gebruiker. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import Mailer from '@/mixins/Mailer'
import Jwt from 'jwt-simple'

export default {
  name: 'Create',
  mixins: [Mailer],
  data () {
    return {
      companies: [{ value: null, text: '----- Selecteer een bedrijf -----' }],
      userRoles: [{ value: null, text: '----- Selecteer een gebruikersrol -----' }],
      showError: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        user_roles_id: null,
        companies_id: null
      },
      formValidation: {
        first_name: null,
        last_name: null,
        email: null,
        emailExist: null,
        user_roles_id: null,
        companies_id: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/local/user-roles').then(response => {
      response.data._embedded.user_roles.forEach((value, index) => {
        this.userRoles.push({
          value: value.id,
          text: value.title
        })
      })
    }).catch(err => {
      console.log(err)
    })

    EducamApi.get('/crm/companies').then(response => {
      response.data.detail._embedded.companies.forEach((value, index) => {
        this.companies.push({
          value: value.id,
          text: value.name
        })
      })
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    async onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.first_name = this.form.first_name !== ''
      this.formValidation.last_name = this.form.last_name !== ''
      this.formValidation.email = this.form.email !== ''
      this.formValidation.user_roles_id = this.form.user_roles_id !== '' && this.form.user_roles_id !== null
      this.formValidation.companies_id = this.form.companies_id !== '' && this.form.companies_id !== null

      const password = Math.random().toString(36).substring(2, 10)

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        EducamApi.post('/local/users', {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          user_roles_id: this.form.user_roles_id,
          password: password,
          companies_id: this.form.companies_id
        }).then(async response => {
          const hash = await Jwt.encode(
            {
              firstName: this.form.first_name,
              lastName: this.form.last_name,
              email: this.form.email,
              dateCreated: response.data.date_created,
              uuid: response.data.uuid,
              id: response.data.id
            }, process.env.VUE_APP_ACCOUNT_SECURITY_TOKEN, 'HS256'
          )

          const email = await this.composeEmail(process.env.VUE_APP_EMAIL_REGISTRATION, [
            {
              needle: 'userName',
              replacer: this.form.first_name
            },
            {
              needle: 'email',
              replacer: this.form.email
            },
            {
              needle: 'password',
              replacer: password
            },
            {
              needle: 'link',
              replacer: process.env.VUE_APP_ACCOUNT_CONFIRMATION_LINK + hash
            }])

          await this.sendMail(this.form.email, this.form.first_name + ' ' + this.form.last_name, process.env.VUE_APP_EMAIL_FROM, process.env.VUE_APP_EMAIL_FROM_NAME, email.subject, email.body)
          this.$router.push({ name: 'UsersRead' })
        }).catch(err => {
          this.showError = true
          console.log(err)
          if (err.response.data.validation_messages.email !== undefined && err.response.data.validation_messages.email.length > 0) {
            this.formValidation.emailExist = false
          }
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.first_name = ''
      this.form.last_name = ''
      this.form.email = ''
      this.form.user_roles_id = null
      this.form.companies_id = null
    },
    resetErrors () {
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
