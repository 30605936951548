import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BreadcrumbPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faBars, faThLarge, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import VueAxios from 'vue-axios'
import DashboardLayout from '@/components/DashboardLayout'
import BlankLayout from '@/components/BlankLayout'
import Vuex from 'vuex'
import Access from '@/mixins/Access'
import Mailer from '@/mixins/Mailer'
import vueDebounce from 'vue-debounce'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import VueCodemirror from 'vue-codemirror'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-dark.css'

// language
import 'codemirror/mode/vue/vue.js'
// active-line.js
import 'codemirror/addon/selection/active-line.js'
// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/match-highlighter.js'
// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'

require('vue2-animate/dist/vue2-animate.min.css')
require('@/assets/css/style.css')
require('@/assets/css/custom.css')

library.add(faSignOutAlt, faBars, faThLarge, faArrowLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-multiselect-listbox', vMultiselectListbox)

Vue.config.productionTip = false
Vue.use(BootstrapVue, VueAxios, axios, Vuex, BreadcrumbPlugin)
Vue.use(vueDebounce)
Vue.use(vMultiselectListbox)
Vue.use(CKEditor)
Vue.config.productionTip = false

Vue.use(VueCodemirror, {
  options: {
    lineNumbers: true,
    line: true,
    tabSize: 4,
    matchBrackets: true,
    mode: 'text/html',
    styleActiveLine: true,
    foldGutter: true
  }
})

Vue.component('dashboard-layout', DashboardLayout)
Vue.component('blank-layout', BlankLayout)

new Vue({
  mixins: [Access, Mailer],
  router,
  store,
  render: h => h(App)
}).$mount('#app')
