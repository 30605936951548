<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Opleidingen selecteren</h5>
      </div>
      <div class="ibox-content float-e-margins">
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-for="courses"
            label-align="left"
            label-class="required"
          >
            <v-multiselect-listbox
              :state="formValidation.courses"
              v-model="form.courses"
              :options="courses"
              :reduce-display-property="(option) => option.label"
              :reduce-value-property="(option) => option.code"
            ></v-multiselect-listbox>
            <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
              Een titel is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Volgende</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="!this.formValidation.courses">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Je moet verplicht minstens één opleiding selecteren</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Courses',
  data () {
    return {
      courses: [],
      showError: false,
      form: {
        courses: []
      },
      formValidation: {
        courses: true
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.courses = this.form.courses.length > 0

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        const componentData = this.$store.getters.componentData
        componentData.courses = this.form.courses
        this.$store.dispatch('setComponentData', componentData)
        this.$router.push({ name: 'ComponentDefaultCourse' })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.courses = ''
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  },
  beforeCreate () {
    const componentData = this.$store.getters.componentData

    if (componentData === null) {
      this.$router.push({ name: 'ComponentsCreate' })
    }

    const suffix = '?company=' + this.$store.getters.companyId + '&type=' + componentData.course_types_id + '&sort=desc'
    EducamApi.get('/courses' + suffix).then((response) => {
      if (response.data.detail.total_items > 0) {
        response.data.detail._embedded.courses.forEach((course, key) => {
          const item = {
            label: course.title + ' (' + course.code + ')',
            code: course.id
          }
          this.courses.push(item)
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
