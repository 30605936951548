<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Opleiding bewerken <small>Vul alle velden in om de opleiding te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>De opleiding is succesvol bewerkt.</b-alert>
        </transition>
        <b-form-group
          label-cols-sm="2"
          content-cols-sm
          label="Opleidingstype"
          label-for="courseType"
          label-align="left"
          label-align-sm="right"
          label-class="required"
        >
          <b-form-select
            id="course_types_id"
            v-model="form.course_types_id"
            :options="courseTypes"
            :state="formValidation.course_types_id"
          ></b-form-select>
          <b-form-invalid-feedback :state="formValidation.course_types_id">
            Opleidingstype is verplicht
          </b-form-invalid-feedback>
        </b-form-group>

        <transition name="fadeIn">
          <webinar-form @formSubmit="onSubmit" :course="this.course" v-if="this.form.course_types_id === 1"></webinar-form>
        </transition>
        <transition name="fadeIn">
          <ilias-form @formSubmit="onSubmit" :course="this.course" v-if="this.form.course_types_id === 5 || this.form.course_types_id === 6"></ilias-form>
        </transition>

        <transition name="fade">
          <b-row v-if="showError">
            <b-col sm="12">
              <hr class="hr-line-dashed" />
            </b-col>
            <b-col sm="4" offset-sm="2">
              <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de opleiding. Probeer opnieuw.</b-alert>
            </b-col>
          </b-row>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import WebinarForm from '@/components/forms/courses/Webinar'
import IliasForm from '@/components/forms/courses/Ilias'

export default {
  name: 'Update',
  components: { WebinarForm, IliasForm },
  data () {
    return {
      course: {},
      showSuccess: false,
      showError: false,
      courseTypes: [{ value: '', text: '----- Selecteer een opleidingstype -----' }],
      form: {
        course_types_id: ''
      },
      formValidation: {
        course_types_id: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/courses/types').then(response => {
      response.data.detail._embedded.types.forEach((value, index) => {
        this.courseTypes.push({
          value: value.id,
          text: value.title
        })
      })
    }).catch(err => {
      console.log(err)
    })

    EducamApi.get('/courses/' + this.$route.params.id).then(response => {
      this.course = response.data.detail
      this.form.course_types_id = response.data.detail.course_types_id
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (formData) {
      this.showSuccess = false
      this.resetErrors()

      let postData = {}

      if (this.form.course_types_id === parseInt(process.env.VUE_APP_COURSE_WEBINAR_ID)) {
        postData = {
          title: formData.title,
          code: formData.code,
          max_students: formData.max_students,
          link: formData.link,
          starting_date: formData.starting_date + ' ' + formData.starting_time,
          active: formData.active === 'active',
          course_types_id: this.form.course_types_id,
          companies_id: this.$store.getters.companyId,
          reminder_email_id: formData.reminder_email_id,
          reminder_date: formData.reminder_date + ' ' + formData.reminder_time,
          subscription_email_id: formData.subscription_email_id
        }
      }

      if (this.form.course_types_id === parseInt(process.env.VUE_APP_COURSE_ILIAS_ID) || this.form.course_types_id === parseInt(process.env.VUE_APP_COURSE_ILIAS_COMPANY_ID)) {
        postData = {
          title: formData.title,
          code: formData.code,
          max_students: formData.max_students,
          course_types_id: this.form.course_types_id,
          companies_id: this.$store.getters.companyId,
          subscription_email_id: formData.subscription_email_id
        }
      }

      this.formValidation.course_types_id = this.form.course_types_id !== ''

      EducamApi.put('/courses/' + this.$route.params.id, postData).then(response => {
        this.showSuccess = true

        setTimeout(() => {
          this.showSuccess = false
        }, 5000)
      }).catch(err => {
        this.showError = true
        console.log(err)
      })
    },
    onReset (event) {
      event.preventDefault()
      this.form.course_types_id = this.course.course_types_id
    },
    resetErrors () {
      this.showError = false
    }
  }
}
</script>

<style scoped>

</style>
