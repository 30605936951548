<template>
  <div class="title-action">
    <b-dropdown id="dropdown-1" text="Acties" variant="primary">
      <b-dropdown-item :to="{name: 'ComponentsUpdate', params: {id: this.$route.params.id}}"><i class="fa fa-wrench"></i> Hoofdinstellingen</b-dropdown-item>
      <b-dropdown-item :to="{name: 'ComponentCoursesUpdate', params: {id: this.$route.params.id}}"><i class="fa fa-book"></i> Opleidingen</b-dropdown-item>
      <b-dropdown-item :to="{name: 'ComponentDefaultCourseUpdate', params: {id: this.$route.params.id}}"><i class="fa fa-exclamation"></i> Standaard opleiding</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'BtnActions'
}
</script>

<style scoped>

</style>
