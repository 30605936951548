<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Gebruikersrol bewerken <small>Vul alle velden in om de gebruikersrol te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>De gebruikersrol is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Titel"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="title"
              type="text"
              v-model="form.title"
              :state="formValidation.title && formValidation.titleExist"
              placeholder="Voor een titel in voor de gebruikersrol"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
              Een titel is verplicht om de gebruikersrol te bewerken
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="formValidation.titleExist" v-if="formValidation.titleExist === false">
              De opgegeven gebruikersrol bestaat reeds
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="Grant types"
            v-slot="{ ariaDescribedby }"
            label-cols-sm="2"
            label-for="grants"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-checkbox-group
              id="grants"
              :options="grants"
              v-model="form.grants"
              :aria-describedby="ariaDescribedby"
              name="grants"
              stacked
              :state="formValidation.grants"
            ></b-form-checkbox-group>
            <b-form-invalid-feedback :state="formValidation.grants">
              Je kan geen gebruikersrol bewerken zonder grant types
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de gebruikersrol. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Update',
  data () {
    return {
      role: {},
      grants: [],
      showSuccess: false,
      showError: false,
      form: {
        title: '',
        grants: []
      },
      formValidation: {
        title: null,
        titleExist: null,
        grants: null
      }
    }
  },
  mounted () {
    EducamApi.get('/local/user-roles/' + this.$route.params.id).then(response => {
      this.form.title = response.data.title
      this.role = response.data

      response.data.grants.forEach(grant => {
        this.form.grants.push(grant.id)
      })
    })

    EducamApi.get('/local/grants').then((data) => {
      if (data.data.total_items > 0) {
        for (const grant of data.data._embedded.grants) {
          this.grants.push({ text: grant.title, value: grant.id })
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.resetErrors()

      this.formValidation.title = this.form.title !== ''
      this.formValidation.grants = this.form.grants.length > 0

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        EducamApi.put('/local/user-roles/' + this.$route.params.id, {
          title: this.form.title,
          grant_types: this.form.grants
        }).then(response => {
          this.showSuccess = true

          setTimeout(() => {
            this.showSuccess = false
          }, 5000)
        }).catch(err => {
          this.showError = true
          if (err.response.data.validation_messages.title.length > 0) {
            this.formValidation.titleExist = false
          }
          console.log(err.response)
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = this.role.title
      this.form.grants = []

      this.role.grants.forEach((value, index) => {
        this.form.grants.push(value.id)
      })
    },
    resetErrors () {
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
