<template>
  <div>
    <b-row>
      <b-col md="6">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Web component bewerken <small>Vul alle velden in om de web component te bewerken</small></h5>
          </div>
          <div class="ibox-content float-e-margins">
            <transition name="fade">
              <b-alert variant="success" v-if="showSuccess" show>De web component is succesvol bewerkt.</b-alert>
            </transition>
            <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Link algemene voorwaarden"
                label-for="conditions_url"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-input
                  id="conditions_url"
                  type="text"
                  v-model="form.conditions_url"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Knop tekst kleur"
                label-for="css_btn_color"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-input
                  id="css_btn_color"
                  type="color"
                  v-model="form.css_btn_color"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Knop achtergrondkleur"
                label-for="css_btn_color"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-input
                  id="css_btn_bg_color"
                  type="color"
                  v-model="form.css_btn_bg_color"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Lettertype"
                label-for="css_font_family"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-input
                  id="css_font_family"
                  type="text"
                  v-model="form.css_font_family"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Tekst kleur"
                label-for="css_font_color"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-input
                  id="css_font_color"
                  type="color"
                  v-model="form.css_font_color"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Success bericht NL"
                label-for="success_message_nl"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-textarea
                  id="css_font_color"
                  v-model="form.success_message_nl"
                  rows="10"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                label-cols-sm="2"
                content-cols-sm
                label="Success bericht FR"
                label-for="success_message_fr"
                label-align="left"
                label-align-sm="right"
              >
                <b-form-textarea
                  id="css_font_color"
                  v-model="form.success_message_fr"
                  rows="10"
                ></b-form-textarea>
              </b-form-group>

              <hr class="hr-line-dashed" />
              <b-col sm="10" offset-sm="2">
                <b-button type="submit" variant="primary">Bewerken</b-button>
                <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
                <b-button type="button" :to="{name: 'ComponentsRead'}" class="ml-2" variant="info">Afsluiten</b-button>
              </b-col>

              <transition name="fade">
                <b-row v-if="showError">
                  <b-col sm="12">
                    <hr class="hr-line-dashed" />
                  </b-col>
                  <b-col sm="4" offset-sm="2">
                    <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de web component. Probeer opnieuw.</b-alert>
                  </b-col>
                </b-row>
              </transition>
            </b-form>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Voorbeeld web component</h5>
          </div>
          <div class="ibox-content float-e-margins">
            <iframe
              ref="iframe"
              class="iframe"
              :src="iframeUrl"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Implementatie</h5>
          </div>
          <div class="ibox-content float-e-margins">
            <b-form-group
              label-cols-sm="2"
              content-cols-sm
              label="Header code"
              description="Kopiëer deze code en plaats ze in de head sectie van de website"
            >
              <b-form-textarea
                id="headerCode"
                v-model="htmlDecodedHeaderCode"
                rows="5"
                readonly
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              label-cols-sm="2"
              content-cols-sm
              label="Component code"
              description="Kopiëer deze code en plaats ze in de website waar de component zichtbaar moet zijn"
            >
              <b-form-textarea
                id="headerCode"
                v-model="htmlDecodedComponentCode"
                rows="5"
                readonly
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Finish',
  data () {
    return {
      headerCode: '&lt;script src="https://unpkg.com/vue"&gt;&lt;/script&gt;\r&lt;script src="' + process.env.VUE_APP_COMPONENTS_URL + '/educam-course-form/educam-course-form.min.js"&gt;&lt;/script&gt;',
      htmlDecodedHeaderCode: null,
      htmlDecodedComponentCode: '<educam-course-form token="%s"></educam-course-form>',
      iframeUrl: process.env.VUE_APP_COMPONENTS_URL + '/educam-course-form/index.php?token=',
      componentUrl: process.env.VUE_APP_COMPONENTS_URL + '/educam-course-form/index.php?token=',
      componentData: null,
      showError: false,
      showSuccess: false,
      form: {
        conditions_url: '',
        css_btn_color: '#000000',
        css_btn_bg_color: '#FFFFFF',
        css_font_family: 'Arial',
        css_font_color: '#000000',
        success_message_nl: '',
        success_message_fr: ''
      }
    }
  },
  methods: {
    htmlDecode () {
      const e = document.createElement('div')
      e.innerHTML = this.headerCode
      this.htmlDecodedHeaderCode = e.childNodes[0].nodeValue
    },
    onSubmit (event) {
      event.preventDefault()

      const selectedCourses = []

      this.componentData.courses.forEach((value, key) => {
        selectedCourses.push(value.id)
      })

      EducamApi.put('courses/components/' + this.$route.params.id, {
        title: this.componentData.title,
        conditions_url: this.form.conditions_url,
        css_btn_color: this.form.css_btn_color,
        css_btn_bg_color: this.form.css_btn_bg_color,
        css_font_family: this.form.css_font_family,
        css_font_color: this.form.css_font_color,
        default_course: this.componentData.default_course,
        success_message_nl: this.form.success_message_nl,
        success_message_fr: this.form.success_message_fr,
        courses: selectedCourses,
        companies_id: this.$store.getters.companyId
      }).then(response => {
        this.iframeUrl = null
        this.showSuccess = true

        this.componentData = response.data.detail
        setTimeout(() => {
          this.showSuccess = false
        }, 5000)
        setTimeout(() => {
          this.iframeUrl = this.componentUrl
        }, 1000)
      }).catch(err => {
        console.log(err)
      })
    },
    onReset (event) {
      event.preventDefault()
      this.form.conditions_url = this.componentData.conditions_url
      this.form.css_btn_color = this.componentData.css_btn_color
      this.form.css_btn_bg_color = this.componentData.css_btn_bg_color
      this.form.css_font_family = this.componentData.css_font_family
      this.form.css_font_color = this.componentData.css_font_color
      this.form.success_message_fr = this.componentData.success_message_fr
      this.form.success_message_nl = this.componentData.success_message_nl
    }
  },
  async beforeCreate () {
    EducamApi.get('courses/components/' + this.$route.params.id).then(response => {
      this.componentData = response.data.detail
      this.form.conditions_url = this.componentData.conditions_url
      this.form.css_btn_color = this.componentData.css_btn_color
      this.form.css_btn_bg_color = this.componentData.css_btn_bg_color
      this.form.css_font_family = this.componentData.css_font_family
      this.form.css_font_color = this.componentData.css_font_color
      this.form.success_message_fr = this.componentData.success_message_fr
      this.form.success_message_nl = this.componentData.success_message_nl
      this.iframeUrl += response.data.detail.jwt
      this.componentUrl = this.iframeUrl
      this.htmlDecodedComponentCode = this.htmlDecodedComponentCode.replace('%s', this.componentData.jwt)
    }).catch(err => {
      console.log(err)
    })

    if (this.componentData === null) {
      await this.$router.push({ name: 'ComponentsCreate' })
    }
  },
  beforeMount () {
    this.htmlDecode()
  }
}
</script>

<style scoped>
  iframe {
    min-height:815px;
    width:100%;
  }
</style>
