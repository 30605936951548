<template>
  <div class="middle-box text-center animated fadeInDown">
    <h1>401</h1>
    <h3 class="font-bold">Geen toegang</h3>

    <div class="error-desc">
      Sorry, het lijkt dat je geen toegang hebt tot deze pagina.
      <div class="form-group mt-3">
        <a href="/" class="btn btn-primary">Aanmelden</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
  mounted () {
    document.body.classList.add('gray-bg')
  }
}
</script>

<style scoped>

</style>
