import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    hasAccess: function (grantTypes, type = 'all') {
      const userGrants = this.$store.getters.grants
      let accessGranted = type === 'all'

      if (userGrants !== undefined && userGrants !== null) {
        for (let i = 0; i < grantTypes.length; i++) {
          if (type === 'all' && userGrants.indexOf(grantTypes[i]) === -1) {
            accessGranted = false
          }

          if (type !== 'all' && userGrants.indexOf(grantTypes[i]) > -1) {
            accessGranted = true
          }
        }
      }
      return accessGranted
    }
  }
}
