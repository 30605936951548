<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Gebruikers</h5>
        <div class="ibox-tools">
          <router-link :to="{name: 'UsersCreate'}" class="btn btn-primary btn-xs">Gebruiker toevoegen</router-link>
        </div>
      </div>
      <div class="ibox-content">
        <b-table
          striped
          hover
          :items="users"
          :fields="fields"
          show-empty
        >
          <template v-slot:empty>
            <b-row>
              <b-col cols="12">
                <h4 class="text-center text-muted">
                  Er zijn geen gebruikers om weer te geven
                </h4>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(id)="row">
            {{ row.item.id }}
          </template>
          <template v-slot:cell(first_name)="row">
            {{ row.item.first_name + ' ' + row.item.last_name }}
          </template>
          <template v-slot:cell(actions)="row">
            <div class="ibox-links">
              <b-link :to="{name: 'UsersUpdate', params: {id: row.item.id}}" class="btn btn-white btn-bitbucket" ><i class="fa fa-wrench text-info"></i></b-link>
              <b-link  class="btn btn-white btn-bitbucket" v-if="userInfo.uuid !== row.item.uuid" @click="showDeleteModal(row.item.id, row.item.first_name + ' ' + row.item.last_name)"><i class="fa fa-trash text-danger"></i></b-link>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import { mapGetters } from 'vuex'

export default {
  name: 'Read',
  data () {
    return {
      users: [],
      companies: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'first_name', label: 'Naam', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company', label: 'Bedrijf', sortable: true },
        { key: '_embedded.userRole.title', label: 'Gebruikersrol', sortable: true },
        { key: 'actions', label: 'Acties', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  beforeMount () {
    EducamApi.get('/crm/companies').then(response => {
      this.companies = response.data.detail._embedded.companies

      EducamApi.get('/local/users').then((data) => {
        if (data.data.total_items > 0) {
          this.users = data.data._embedded.users

          this.users.forEach((value, key) => {
            let userCompany = null
            this.companies.forEach((company, companyKey) => {
              if (company.id === value.companies_id) {
                userCompany = company.name
              }
            })
            this.users[key].company = userCompany
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    showDeleteModal (userId, userName) {
      const h = this.$createElement
      const messageVNode = h('p', {}, ['Je staat op het punt om de gebruiker ', h('strong', {}, userName), ' te verwijderen.', h('br'), ' Weet je zeker dat je wil doorgaan?'])
      const buttonVNode = h('span', {}, ['Verwijderen ', h('i', { class: 'fa fa-trash' })])
      this.$bvModal.msgBoxConfirm(messageVNode, {
        title: 'Gebruiker verwijderen',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: buttonVNode,
        cancelTitle: 'Annuleren',
        hideHeaderClose: true,
        centered: true
      }).then(async value => {
        if (value === true) {
          await EducamApi.delete('/local/users/' + userId)
          EducamApi.get('/local/users').then((data) => {
            if (data.data.total_items > 0) {
              this.users = data.data._embedded.users
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
