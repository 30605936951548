<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Wachtwoord bewerken <small>Vul alle velden in om uw wachtwoord te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>Uw wachtwoord is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Huidig wachtwoord"
            label-for="current_password"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="current_password"
              type="password"
              v-model="form.current_password"
              :state="formValidation.current_password"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.current_password">
              Het wachtwoord is niet juist
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Nieuw wachtwoord"
            label-for="password"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="password"
              type="password"
              v-model="form.password"
              :state="formValidation.password"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.password">
              Wachtwoord is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Wachtwoord herhalen"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="confirm_password"
              type="password"
              v-model="form.confirm_password"
              :state="formValidation.confirm_password"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.confirm_password">
              De wachtwoorden zijn niet gelijk aan elkaar
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het bewerken van uw wachtwoord. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EducamApi from '@/axios/educam-api.instance'
import EducamGateway from '@/axios/educam-gateway.instance'
import AuthToken from '@/modules/AuthToken'

const authToken = new AuthToken()

export default {
  name: 'Password',
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      showSuccess: false,
      showError: false,
      user: {},
      form: {
        current_password: '',
        password: '',
        confirm_password: ''
      },
      formValidation: {
        current_password: null,
        password: null,
        confirm_password: null
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.showSuccess = false
      this.resetErrors()

      this.formValidation.current_password = this.form.confirm_password !== ''
      this.formValidation.password = this.form.password !== ''
      this.formValidation.confirm_password = this.form.confirm_password === this.form.password

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        EducamGateway.post('/oauth', {
          username: this.$store.getters.email,
          password: this.form.current_password,
          grant_type: 'password'
        }).then(response => {
          authToken.store(response.data)
          EducamApi.get('/local/users?uuid=' + this.$store.getters.uuid).then(response => {
            EducamApi.put('/local/users/' + response.data.id, {
              password: this.form.password
            }).then(response => {
              this.showSuccess = true
              this.formValidation.emailExist = true

              setTimeout(() => {
                this.showSuccess = false
              }, 5000)
            }).catch(err => {
              this.showError = true
              if (err.response.data.validation_messages.email.length > 0) {
                this.formValidation.emailExist = false
              } else {
                this.formValidation.emailExist = true
              }
            })
          }).catch(err => {
            console.log(err)
          })
        }).catch(err => {
          console.log(err)
          this.formValidation.current_password = false
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.first_name = this.$store.getters.firstName
      this.form.last_name = this.$store.getters.lastName
      this.form.email = this.$store.getters.email
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
