import Companies from '@/views/modules/companies/Companies'
import CompaniesCompanies from '@/views/modules/companies/companies/Companies'
import CompaniesCreate from '@/views/modules/companies/companies/Create'
import CompaniesRead from '@/views/modules/companies/companies/Read'
import CompaniesUpdate from '@/views/modules/companies/companies/Update'

import CompanyTypes from '@/views/modules/companies/types/Types'
import CompanyTypesCreate from '@/views/modules/companies/types/Create'
import CompanyTypesUpdate from '@/views/modules/companies/types/Update'
import CompanyTypesRead from '@/views/modules/companies/types/Read'

const routes = {
  path: '/companies/',
  component: Companies,
  meta: {
    breadCrumb: 'Bedrijven',
    breadCrumbDisabled: true,
    grants: ['crm.companies.crud']
  },
  children: [
    {
      path: 'companies/',
      name: 'Companies',
      component: CompaniesCompanies,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Lijst bedrijven',
        pageTitle: 'Lijst bedrijven',
        grants: ['crm.companies.crud']
      },
      children: [
        {
          path: 'create/',
          name: 'CompaniesCreate',
          component: CompaniesCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Bedrijf toevoegen',
            pageTitle: 'Bedrijf toevoegen',
            grants: ['crm.companies.crud']
          }
        },
        {
          path: '',
          name: 'CompaniesRead',
          component: CompaniesRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Bedrijven',
            pageTitle: 'Bedrijven',
            grants: ['crm.companies.crud']
          }
        },
        {
          path: ':id',
          name: 'CompaniesUpdate',
          component: CompaniesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Bedrijf bewerken',
            pageTitle: 'Bedrijf bewerken',
            grants: ['crm.companies.crud']
          }
        }
      ]
    },
    {
      path: 'company-types/',
      name: 'CompanyTypes',
      component: CompanyTypes,
      meta: {
        layout: 'dashboard',
        isAuthenticated: true,
        breadCrumb: 'Lijst bedrijfstypes',
        pageTitle: 'Lijst bedrijfstypes',
        grants: ['crm.companies.types.crud']
      },
      children: [
        {
          path: 'create/',
          name: 'CompanyTypesCreate',
          component: CompanyTypesCreate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Bedrijfstype toevoegen',
            pageTitle: 'Bedrijfstype toevoegen',
            grants: ['crm.companies.types.crud']
          }
        },
        {
          path: '',
          name: 'CompanyTypesRead',
          component: CompanyTypesRead,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Bedrijfstypes',
            pageTitle: 'Bedrijfstypes',
            grants: ['crm.companies.types.crud']
          }
        },
        {
          path: ':id',
          name: 'CompanyTypesUpdate',
          component: CompanyTypesUpdate,
          meta: {
            layout: 'dashboard',
            isAuthenticated: true,
            breadCrumb: 'Bedrijfstype bewerken',
            pageTitle: 'Bedrijfstype bewerken',
            grants: ['crm.companies.types.crud']
          }
        }
      ]
    }
  ]
}

export default routes
