<template>
  <div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
      <div class="navbar-header">
        <a class="navbar-minimalize minimalize-styl-2 btn btn-primary" v-on:click="switchMenu" href="#"><font-awesome-icon icon="bars" /> </a>
        <img :src="educamLogo" width="150" id="educamLogo" />
      </div>
      <ul class="nav navbar-top-links navbar-right">
        <li style="padding: 20px">
          <span class="m-r-sm text-muted welcome-message">Welcome bij Educam Partner.</span>
        </li><li>
          <a href="/logout">
            <font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Afmelden
          </a>
        </li>
      </ul>

    </nav>
  </div>
</template>

<script>

export default {
  name: 'TopBar',
  data: () => {
    return {
      educamLogo: require('./../assets/img/logo-2.svg')
    }
  },
  methods: {
    switchMenu () {
      if (document.body.classList.contains('mini-navbar')) {
        document.body.classList.remove('mini-navbar')
      } else {
        document.body.classList.add('mini-navbar')
      }
    }
  }
}
</script>

<style scoped>
  #educamLogo {
    margin-top:15px;
    margin-left:20px;
  }
</style>
