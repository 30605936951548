<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Email toevoegen <small>Vul alle velden in om een email toe te voegen</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Titel"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="title"
              type="text"
              v-model="form.title"
              :state="formValidation.title"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
              Een titel is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Actie"
            label-for="action"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-select
              id="action"
              type="text"
              :options="this.actions"
              v-model="form.action"
              :state="formValidation.action"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Onderwerp"
            label-for="subject"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="subject"
              type="text"
              v-model="form.subject"
              :state="formValidation.subject"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.subject" v-if="formValidation.subject === false">
              Een onderwerp is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Template"
            label-for="template"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-select
              id="template"
              type="text"
              :options="this.templates"
              v-model="form.template"
              :state="formValidation.template"
            ></b-form-select>
            <b-form-invalid-feedback :state="formValidation.template" v-if="formValidation.template === false">
              Een template is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Inhoud"
            label-for="content"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <ckeditor
              id="content"
              v-model="form.content"
              :state="formValidation.content"
              :editor="ckeditor"
            ></ckeditor>
            <b-form-invalid-feedback :state="formValidation.content" v-if="formValidation.content === false">
              Content is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de email. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import DefaultCk from '@/ckeditor/config/default'

export default {
  name: 'Create',
  data () {
    return {
      ckeditor: DefaultCk,
      templates: [{
        value: '',
        text: '----- Selecteer een template -----'
      }],
      actions: [{
        value: '',
        text: '----- Selecteer een actie -----'
      }],
      showError: false,
      form: {
        title: '',
        content: '',
        subject: '',
        template: '',
        action: ''
      },
      formValidation: {
        title: null,
        content: null,
        template: null,
        subject: null,
        action: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/mailing/templates?company=' + this.$store.getters.companyId).then((data) => {
      if (data.data.detail.total_items > 0) {
        for (const template of data.data.detail._embedded.templates) {
          this.templates.push({ text: template.title, value: template.id })
        }
      }
    }).catch((err) => {
      console.log(err)
    })

    EducamApi.get('/mailing/actions').then((data) => {
      if (data.data.detail.total_items > 0) {
        for (const action of data.data.detail._embedded.actions) {
          this.actions.push({ text: action.name, value: action.id })
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.title = this.form.title !== ''
      this.formValidation.content = this.form.content !== ''
      this.formValidation.template = this.form.template !== ''
      this.formValidation.subject = this.form.subject !== ''

      const validationValues = Object.values(this.formValidation)
      if (validationValues.indexOf(false) < 0) {
        EducamApi.post('/mailing/emails', {
          title: this.form.title,
          content: this.form.content,
          template_id: this.form.template,
          subject: this.form.subject,
          actions_id: this.form.action
        }).then(response => {
          this.$router.push({ name: 'EmailsRead' })
        }).catch(err => {
          this.showError = true
          console.log(err.response)
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = ''
      this.form.content = ''
      this.form.template = ''
      this.form.subject = ''
      this.form.action = ''
    },
    resetErrors () {
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
