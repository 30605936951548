<template>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <div>
      <div>

        <h1 class="logo-name">
          <img :src="educamLogo">
        </h1>

      </div>
      <h3>Welkom bij Ecucam</h3>
      <p>Het Educam platform is enkel voor bevoegde personen. Indien je nog geen account hebt kan je ons hiervoor contacteren op <a href="mailto:info@educam.be?subject=Account aanvragen voor Educam platform">info@educam.be</a></p>
      <b-form @submit="onSubmit" class="m-t">
        <b-form-group
          id="email-group"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            required
            placeholder="Uw email adres"
            class="form-control"
            aria-required="true"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="password-group"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            required
            placeholder="Uw wachtwoord"
            class="form-control"
            aria-required="true"
          ></b-form-input>
        </b-form-group>
        <b-button block full-width variant="primary" type="submit" class="m-b">Login</b-button>
        <router-link to="/forgot-password"><small>Forgot password?</small></router-link>
        <b-alert :show="showError" variant="danger" class="mt-2">De logingegevens zijn niet correct, probeer opnieuw.</b-alert>
      </b-form>
      <p class="m-t"> <small>Educam administratie paneel &copy; 2020</small> </p>
    </div>
  </div>
</template>
<script>

import EducamGateway from '../../../axios/educam-gateway.instance'
import AuthToken from '@/modules/AuthToken'
import { mapActions } from 'vuex'

const authToken = new AuthToken()

export default {
  name: 'Login',
  data () {
    return {
      educamLogo: require('./../../../assets/img/logo-2.svg'),
      form: {
        email: '',
        password: ''
      },
      showError: false
    }
  },
  methods: {
    ...mapActions([
      'fetch'
    ]),
    async onSubmit (evt) {
      evt.preventDefault()
      this.showError = false
      await EducamGateway.post('oauth', {
        grant_type: 'password',
        username: this.form.email,
        password: this.form.password
      }).then(async (data) => {
        this.showError = false
        authToken.store(data.data)
        await this.fetch()
        this.$router.push('/dashboard')
      }).catch((error) => {
        this.showError = true
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
