<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Opleiding statistieken</h5>
        <div class="ibox-tools">

        </div>
      </div>
      <div class="ibox-content">
        <bar-chart :chart-labels="this.labels" :chart-data="this.chartData" v-if="this.loaded"></bar-chart>
      </div>
    </div>

    <div class="ibox">
      <div class="ibox-title">
        <h5>Inschrijvingen</h5>
      </div>
      <div class="ibox-content">
        <b-table
          striped
          hover
          :items="subscriptions"
          :fields="fields"
          show-empty
          id="subscriptionsTable"
        >
          <template v-slot:empty>
            <b-row>
              <b-col cols="12">
                <h4 class="text-center text-muted">
                  Er zijn geen inschrijvingen voor deze opleiding momenteel
                </h4>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(name)="row">
            <span v-for="user in users" :key="user.id">
              <span v-if="row.item.uuid === user.uuid">{{ user.first_name + ' ' + user.last_name }}</span>
            </span>
          </template>
          <template v-slot:cell(company)="row">
            {{ (row.item.company !== undefined) ? row.item.company.name : null }}
            <strong v-if="row.item.company !== undefined && row.item.company.parent_company !== undefined"> ( {{ row.item.company.parent_company.name }} )</strong>
          </template>
          <template v-slot:cell(email)="row">
            <span v-for="user in users" :key="user.id">
              <span v-if="row.item.uuid === user.uuid">{{ user.email }}</span>
            </span>
          </template>
          <template v-slot:cell(date_created)="row">
            {{ formatDate(row.item.date_created.date) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/CourseBarChart'
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Charts',
  components: { BarChart },
  data: () => {
    return {
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Naam cursist', sortable: true },
        { key: 'email', label: 'Email cursist', sortable: true },
        { key: 'company', label: 'Bedrijf', sortable: true },
        { key: 'date_created', label: 'Datum inschrijving', sortable: true }
      ],
      users: [],
      subscriptions: [],
      loaded: false,
      labels: [],
      chartData: []
    }
  },
  methods: {
    formatDate (dateValue) {
      const date = new Date(dateValue.slice(0, 10))
      return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    }
  },
  beforeMount () {
    EducamApi.get('/courses/' + this.$route.params.id).then(response => {
      if (response.data.detail.subscriptions.length > 0) {
        this.subscriptions = response.data.detail.subscriptions
        const currentDate = new Date()
        const courseDate = (response.data.detail.starting_date !== null) ? new Date(response.data.detail.starting_date.date.slice(0, 10)) : null
        let firstSubscriptionDate = new Date(response.data.detail.subscriptions[0].date_created.date.slice(0, 10))
        const endDate = (courseDate === null || currentDate < courseDate) ? currentDate : courseDate
        const uuid = []

        while (firstSubscriptionDate < endDate) {
          this.labels.push(firstSubscriptionDate.getDate() + '-' + (firstSubscriptionDate.getMonth() + 1))
          const newDate = firstSubscriptionDate.setDate(firstSubscriptionDate.getDate() + 1)
          firstSubscriptionDate = new Date(newDate)
        }

        if (this.labels.length > 31) {
          this.labels = this.labels.slice(this.labels.length - 31)
        }

        this.labels.forEach((value, key) => {
          this.chartData[key] = 0
          response.data.detail.subscriptions.forEach((subscription, subscriptionKey) => {
            const date = new Date(subscription.date_created.date.slice(0, 10))

            if (uuid.indexOf(subscription.uuid) === -1) {
              uuid.push(subscription.uuid)
            }

            if (date.getDate() + '-' + (date.getMonth() + 1) === value) {
              this.chartData[key]++
            }
          })
        })

        EducamApi.post('/local/user-select', {
          uuid: uuid
        }).then(response => {
          this.users = response.data._embedded
        }).catch(err => {
          console.log(err)
        })
        this.loaded = true
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
