<template>
  <div>
    <b-row>
      <b-col md="6">
          <div class="ibox">
            <div class="ibox-title">
              <h5>Web component bewerken <small>Vul alle velden in om de web component te bewerken</small></h5>
            </div>
            <div class="ibox-content float-e-margins">
              <transition name="fade">
                <b-alert variant="success" v-if="showSuccess" show>De web component is succesvol bewerkt.</b-alert>
              </transition>
              <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Titel"
                  label-for="title"
                  label-align="left"
                  label-align-sm="right"
                  label-class="required"
                >
                  <b-form-input
                    id="title"
                    type="text"
                    v-model="form.title"
                    :state="formValidation.title"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
                    Een titel is verplicht
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Opleidingstype"
                  label-for="courseType"
                  label-align="left"
                  label-align-sm="right"
                  label-class="required"
                >
                  <b-form-select
                    id="course_types_id"
                    v-model="form.course_types_id"
                    :options="courseTypes"
                    :state="formValidation.course_types_id"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="formValidation.course_types_id">
                    Opleidingstype is verplicht
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Link algemene voorwaarden"
                  label-for="conditions_url"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-input
                    id="conditions_url"
                    type="text"
                    v-model="form.conditions_url"
                    :state="formValidation.conditions_url"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Knop tekst kleur"
                  label-for="css_btn_color"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-input
                    id="css_btn_color"
                    type="color"
                    v-model="form.css_btn_color"
                    :state="formValidation.css_btn_color"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Knop achtergrondkleur"
                  label-for="css_btn_color"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-input
                    id="css_btn_bg_color"
                    type="color"
                    v-model="form.css_btn_bg_color"
                    :state="formValidation.css_btn_bg_color"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Lettertype"
                  label-for="css_font_family"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-input
                    id="css_font_family"
                    type="text"
                    v-model="form.css_font_family"
                    :state="formValidation.css_font_family"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Tekst kleur"
                  label-for="css_font_color"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-input
                    id="css_font_color"
                    type="color"
                    v-model="form.css_font_color"
                    :state="formValidation.css_font_color"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Success bericht NL"
                  label-for="success_message_nl"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-textarea
                    id="css_font_color"
                    v-model="form.success_message_nl"
                    :state="formValidation.success_message_nl"
                    rows="10"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  label-cols-sm="2"
                  content-cols-sm
                  label="Success bericht FR"
                  label-for="success_message_fr"
                  label-align="left"
                  label-align-sm="right"
                >
                  <b-form-textarea
                    id="css_font_color"
                    v-model="form.success_message_fr"
                    :state="formValidation.success_message_fr"
                    rows="10"
                  ></b-form-textarea>
                </b-form-group>

                <hr class="hr-line-dashed" />
                <b-col sm="4" offset-sm="2">
                  <b-button type="submit" variant="primary">Opslaan</b-button>
                  <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
                </b-col>

                <transition name="fade">
                  <b-row v-if="showError">
                    <b-col sm="12">
                      <hr class="hr-line-dashed" />
                    </b-col>
                    <b-col sm="4" offset-sm="2">
                      <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de web component. Probeer opnieuw.</b-alert>
                    </b-col>
                  </b-row>
                </transition>
              </b-form>
            </div>
          </div>
      </b-col>
      <b-col md="6">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Voorbeeld web component</h5>
          </div>
          <div class="ibox-content float-e-margins">
            <iframe
              ref="iframe"
              class="iframe"
              :src="iframeUrl"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>Implementatie</h5>
          </div>
          <div class="ibox-content float-e-margins">
            <b-form-group
              label-cols-sm="2"
              content-cols-sm
              label="Header code"
              description="Kopiëer deze code en plaats ze in de head sectie van de website"
            >
              <b-form-textarea
                id="headerCode"
                v-model="htmlDecodedHeaderCode"
                rows="5"
                readonly
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              label-cols-sm="2"
              content-cols-sm
              label="Component code"
              description="Kopiëer deze code en plaats ze in de website waar de component zichtbaar moet zijn"
            >
              <b-form-textarea
                id="headerCode"
                v-model="htmlDecodedComponentCode"
                rows="5"
                readonly
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Update',
  data () {
    return {
      courseTypes: [{ value: '', text: '----- Selecteer een opleidingstype -----' }],
      headerCode: '&lt;script src="https://unpkg.com/vue"&gt;&lt;/script&gt;\r&lt;script src="' + process.env.VUE_APP_COMPONENTS_URL + '/educam-course-form/educam-course-form.min.js"&gt;&lt;/script&gt;',
      htmlDecodedHeaderCode: null,
      htmlDecodedComponentCode: '<educam-course-form token="%s"></educam-course-form>',
      iframeUrl: null,
      componentUrl: process.env.VUE_APP_COMPONENTS_URL + '/educam-course-form/index.php?token=',
      component: null,
      selectedCourses: [],
      showError: false,
      showSuccess: false,
      company: {},
      form: {
        title: '',
        conditions_url: '',
        css_btn_color: '',
        css_btn_bg_color: '',
        css_font_family: '',
        css_font_color: '',
        success_message_nl: '',
        success_message_fr: '',
        course_types_id: ''
      },
      formValidation: {
        title: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/courses/components/' + this.$route.params.id).then(response => {
      this.component = response.data.detail
      this.form.title = response.data.detail.title
      this.form.conditions_url = response.data.detail.conditions_url
      this.form.css_btn_color = response.data.detail.css_btn_color
      this.form.css_btn_bg_color = response.data.detail.css_btn_bg_color
      this.form.css_font_family = response.data.detail.css_font_family
      this.form.css_font_color = response.data.detail.css_font_color
      this.form.success_message_nl = response.data.detail.success_message_nl
      this.form.success_message_fr = response.data.detail.success_message_fr
      this.form.course_types_id = response.data.detail.course_types_id
      this.jwt = response.data.detail.jwt
      this.componentUrl += response.data.detail.jwt
      this.iframeUrl = this.componentUrl
      this.htmlDecodedComponentCode = this.htmlDecodedComponentCode.replace('%s', this.component.jwt)

      this.component.courses.forEach((value, key) => {
        this.selectedCourses.push(value.id)
      })
    }).catch(err => {
      console.log(err)
    })

    EducamApi.get('/courses/types').then(response => {
      response.data.detail._embedded.types.forEach((value, index) => {
        this.courseTypes.push({
          value: value.id,
          text: value.title
        })
      })
    }).catch(err => {
      console.log(err)
    })

    this.htmlDecode()
  },
  methods: {
    htmlDecode () {
      const e = document.createElement('div')
      e.innerHTML = this.headerCode
      this.htmlDecodedHeaderCode = e.childNodes[0].nodeValue
    },
    onSubmit (event) {
      event.preventDefault()
      this.showSuccess = false
      this.showError = false

      this.resetErrors()

      this.formValidation.title = this.form.title !== ''
      this.formValidation.course_types_id = this.form.course_types_id !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        EducamApi.put('/courses/components/' + this.$route.params.id, {
          title: this.form.title,
          conditions_url: this.form.conditions_url,
          css_btn_color: this.form.css_btn_color,
          css_btn_bg_color: this.form.css_btn_bg_color,
          css_font_family: this.form.css_font_family,
          css_font_color: this.form.css_font_color,
          default_course: this.component.default_course,
          success_message_nl: this.form.success_message_nl,
          success_message_fr: this.form.success_message_fr,
          courses: this.selectedCourses,
          companies_id: this.$store.getters.companyId,
          course_types_id: this.form.course_types_id
        }).then(response => {
          this.showSuccess = true
          this.iframeUrl = null

          setTimeout(() => {
            this.iframeUrl = this.componentUrl
          }, 1000)

          setTimeout(() => {
            this.showSuccess = false
          }, 5000)
        }).catch(err => {
          this.showError = true
          console.log(err.response)
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = this.component.title
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>
iframe {
  min-height:920px;
  width:100%;
}
</style>
