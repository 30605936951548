<template>
  <div></div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Confirmation',
  beforeCreate () {
    axios.post(process.env.VUE_APP_API_URL + '/user-activation', {
      token: this.$route.params.id
    }).then(response => {
      if (response.status === 202) {
        this.$router.push({ name: 'Login' })
      } else {
        this.$router.push('/unauthorized')
      }
    }).catch((err) => {
      console.log(err)
      this.$router.push('/unauthorized')
    })
  }
}
</script>

<style scoped>

</style>
