const state = {
  componentData: null
}

const mutations = {
  'UPDATE_DATA' (state, value) {
    console.log(value)
    state.componentData = value
  },
  'CLEAR_INFO' (state) {
    state.componentData = null
  }
}

const actions = {
  clearComponentData ({ commit }, body) {
    return new Promise((resolve) => {
      commit('CLEAR_INFO')
      resolve()
    })
  },
  setComponentData ({ commit }, body) {
    commit('UPDATE_DATA', body)
  }
}

const getters = {
  componentData (state) {
    return state.componentData
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
