<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Bedrijf bwerken <small>Vul alle velden in om het bedrijf te bewerken</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>Het bedrijf is succesvol bewerkt.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Naam"
            label-for="name"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="name"
              type="text"
              v-model="form.name"
              :state="formValidation.name"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.name" v-if="formValidation.name === false">
              Een naam is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Bedrijfstype"
            label-for="company_type"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-select
              id="concept"
              :options="companyTypes"
              v-model="form.company_type"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Concept"
            label-for="concept"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-select
              id="concept"
              :options="concepts"
              v-model="form.concept"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Email"
            label-for="email"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="email"
              type="email"
              v-model="form.email"
              :state="formValidation.email"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.email" v-if="formValidation.email === false">
              Een email is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="BTW nummer"
            label-for="email"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="vat"
              type="text"
              v-model="form.vat"
              :state="formValidation.vat"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.vat" v-if="formValidation.vat === false">
              BTW nummer is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Straat"
            label-for="street"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="vat"
              type="text"
              v-model="form.street"
              :state="formValidation.street"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.street" v-if="formValidation.street === false">
              Straat is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Nummer"
            label-for="number"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="number"
              type="text"
              v-model="form.number"
              :state="formValidation.number"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.number" v-if="formValidation.number === false">
              Nummer is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Postcode"
            label-for="postal_code"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="vat"
              type="text"
              v-model="form.postal_code"
              :state="formValidation.postal_code"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.postal_code" v-if="formValidation.postal_code === false">
              Postcode is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Gemeente"
            label-for="town"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="vat"
              type="text"
              v-model="form.town"
              :state="formValidation.town"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.town" v-if="formValidation.town === false">
              Gemeente is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Land"
            label-for="country"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="vat"
              type="text"
              v-model="form.country"
              :state="formValidation.country"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.country" v-if="formValidation.country === false">
              Land is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van het bedrijf. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Update',
  data () {
    return {
      concepts: [{
        value: '',
        text: '--- Selecteer concept ---'
      }],
      companyTypes: [{
        value: '',
        text: '--- Selecteer bedrijfstype ---'
      }],
      showError: false,
      showSuccess: false,
      company: {},
      form: {
        name: '',
        email: '',
        vat: '',
        street: '',
        number: '',
        postal_code: '',
        town: '',
        country: '',
        concept: '',
        company_type: ''
      },
      formValidation: {
        name: null,
        email: null,
        vat: null,
        street: null,
        number: null,
        postal_code: null,
        town: null,
        country: null,
        company_type: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/crm/companies/' + this.$route.params.id).then(response => {
      this.company = response.data.detail
      this.form.name = response.data.detail.name
      this.form.email = response.data.detail.email
      this.form.vat = response.data.detail.vat
      this.form.street = response.data.detail.street
      this.form.number = response.data.detail.number
      this.form.postal_code = response.data.detail.postal_code
      this.form.town = response.data.detail.town
      this.form.country = response.data.detail.country
      this.form.company_type = (response.data.detail.company_types_id !== null) ? response.data.detail.company_types_id : ''
      this.form.concept = (response.data.detail.parent_companies_id !== null) ? response.data.detail.parent_companies_id : ''
    }).catch(err => {
      console.log(err)
    })

    EducamApi.get('/crm/company-types').then(response => {
      response.data.detail._embedded.company_types.forEach((value, key) => {
        this.companyTypes.push({
          value: value.id,
          text: value.type
        })
      })
    }).catch(err => {
      console.log(err)
    })

    EducamApi.get('/crm/companies?type=1').then(response => {
      response.data.detail._embedded.companies.forEach((value, key) => {
        this.concepts.push({
          value: value.id,
          text: value.name
        })
      })
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.showSuccess = false

      this.resetErrors()

      this.formValidation.name = this.form.name !== ''
      this.formValidation.email = this.form.email !== ''
      this.formValidation.vat = this.form.vat !== ''
      this.formValidation.street = this.form.street !== ''
      this.formValidation.number = this.form.number !== ''
      this.formValidation.postal_code = this.form.postal_code !== ''
      this.formValidation.town = this.form.town !== ''
      this.formValidation.country = this.form.country !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        EducamApi.put('/crm/companies/' + this.$route.params.id, {
          name: this.form.name,
          email: this.form.email,
          vat: this.form.vat,
          street: this.form.street,
          number: this.form.number,
          postal_code: this.form.postal_code,
          town: this.form.town,
          country: this.form.country,
          company_types_id: this.form.company_type,
          companies_id: this.form.concept
        }).then(response => {
          this.showSuccess = true

          setTimeout(() => {
            this.showSuccess = false
          }, 5000)
        }).catch(err => {
          this.showError = true
          console.log(err.response)
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.name = this.company.name
      this.form.email = this.company.email
      this.form.vat = this.company.vat
      this.form.street = this.company.street
      this.form.number = this.company.number
      this.form.postal_code = this.company.postal_code
      this.form.town = this.company.town
      this.form.country = this.company.country
      this.form.company_type = this.company.company_types_id
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
