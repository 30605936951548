<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Ilias logins</h5>
        <div class="ibox-tools">
          <b-badge variant="primary" v-if="this.totalLogins >= 25">Er zijn nog {{ this.totalLogins }} Logins beschikbaar</b-badge>
          <b-badge variant="warning" v-if="this.totalLogins >= 10 && this.totalLogins < 25">Er zijn nog {{ this.totalLogins }} Logins beschikbaar</b-badge>
          <b-badge variant="danger" v-if="this.totalLogins < 10">Er zijn nog {{ this.totalLogins }} Logins beschikbaar</b-badge>
        </div>
      </div>
      <div class="ibox-content">
        <transition name="fade">
          <b-alert variant="success" v-if="showSuccess" show>De Logins zijn succesvol geïmporteerd.</b-alert>
        </transition>
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group label="Ilias login bestand" label-cols-sm="2">
            <b-form-file
              v-model="csvFile"
              accept=".csv"
              :state="csvValidation"
              placeholder="Selecteer een bestand of sleep het hierheen..."
              drop-placeholder="Sleep uw bestand hierheen..."
            ></b-form-file>
          </b-form-group>
          <div class="mt-3">Geselecteerd bestand: <b-badge variant="success">{{ csvFile ? csvFile.name : '' }}</b-badge></div>
          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Opslaan</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Ilias',
  data: () => {
    return {
      totalLogins: 0,
      csvFile: null,
      csvValidation: null,
      showSuccess: false
    }
  },
  beforeMount () {
    this.getIliasLogins()
  },
  methods: {
    getIliasLogins () {
      EducamApi.get('/courses/ilias?status=0&course=' + this.$route.params.id).then(response => {
        this.totalLogins = response.data.detail.total_items
      }).catch(err => {
        console.log(err)
      })
    },
    onSubmit (event) {
      event.preventDefault()

      const reader = new FileReader()
      reader.onload = (event) => {
        const importData = []
        this.csvContent = event.target.result
        const fileData = event.target.result.split('\r\n')
        console.log(fileData)
        fileData.forEach((row, key) => {
          const rowResult = row.split(';')
          console.log(rowResult)
          importData.push({
            language: rowResult[2],
            login: rowResult[0],
            password: rowResult[1]
          })
        })

        if (importData.length > 0) {
          EducamApi.post('/courses/ilias', { logins: importData, courses_id: this.$route.params.id }).then(response => {
            this.showSuccess = true
            this.getIliasLogins()
            this.csvFile = null
          }).catch(err => {
            console.log(err)
          })
        }
      }
      reader.readAsText(this.csvFile)
    },
    onReset (event) {
      event.preventDefault()
      this.csvFile = null
    }
  }
}
</script>

<style scoped>

</style>
