<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Opleidingen</h5>
        <div class="ibox-tools">
          <router-link :to="{name: 'CoursesCreate'}" class="btn btn-primary btn-xs">Opleiding toevoegen</router-link>
        </div>
      </div>
      <div class="ibox-content">
        <b-table
          striped
          hover
          :items="courses"
          :fields="fields"
          show-empty
          id="coursesTable"
        >
          <template v-slot:empty>
            <b-row>
              <b-col cols="12">
                <h4 class="text-center text-muted">
                  Er zijn geen opleidingen om weer te geven
                </h4>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(id)="row">
            {{ row.item.id }}
          </template>
          <template v-slot:cell(title)="row">
            {{ row.item.title }}
          </template>
          <template v-slot:cell(course_type.title)="row">
            {{ row.item.course_type.title }}
          </template>
          <template v-slot:cell(template)="row">
            {{ row.item.template.title }}
          </template>
          <template v-slot:cell(subscriptions)="row">
            <b-badge variant="primary" v-if="row.item.subscriptions.length < row.item.max_students">{{ row.item.subscriptions.length }}</b-badge>
            <b-badge variant="danger" v-if="row.item.subscriptions.length === row.item.max_students">{{ row.item.subscriptions.length }}</b-badge>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="ibox-links">
              <b-link :to="{name: 'CoursesUpdate', params: {id: row.item.id}}" class="btn btn-white btn-bitbucket" ><i class="fa fa-wrench text-info"></i></b-link>
              <b-link v-if="row.item.course_type.id === 5 || row.item.course_type.id === 6" :to="{name: 'IliasImport', params: {id: row.item.id}}" class="btn btn-white btn-bitbucket" ><i class="fa fa-key text-info"></i></b-link>
              <b-link :to="{name: 'CourseCharts', params: {id: row.item.id}}" class="btn btn-white btn-bitbucket" ><i class="fa fa-line-chart text-info"></i></b-link>
              <b-link  class="btn btn-white btn-bitbucket" @click="showDeleteModal(row.item.id, row.item.title)"><i class="fa fa-trash text-danger"></i></b-link>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="coursesTable"
          @change="nextPage"
          :hide-goto-end-buttons="true"
          prev-text="Vorige"
          next-text="Volgende"
          page-class="paginate_button"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  name: 'Read',
  data () {
    return {
      search: '',
      rows: 0,
      currentPage: 1,
      perPage: 50,
      courses: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'title', label: 'Titel', sortable: true },
        { key: 'course_type.title', label: 'Type', sortable: true },
        { key: 'code', label: 'Code', sortable: true },
        { key: 'max_students', label: 'Max inschrijvingen', sortable: true },
        { key: 'subscriptions', label: 'Inschrijvingen', sortable: true },
        { key: 'link', label: 'Link', sortable: true },
        { key: 'actions', label: 'Acties', sortable: false }
      ]
    }
  },
  methods: {
    searchCourses () {
      console.log('ik ga zoeken')
    },
    showDeleteModal (courseId, courseTitle) {
      const h = this.$createElement
      const messageVNode = h('p', {}, ['Je staat op het punt de opleiding ', h('strong', {}, courseTitle), ' te verwijderen.', h('br'), ' Weet je zeker dat je wil doorgaan?'])
      const buttonVNode = h('span', {}, ['Verwijderen ', h('i', { class: 'fa fa-trash' })])
      this.$bvModal.msgBoxConfirm(messageVNode, {
        title: 'Opleiding verwijderen',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: buttonVNode,
        cancelTitle: 'Annuleren',
        hideHeaderClose: true,
        centered: true
      }).then(async value => {
        if (value === true) {
          await EducamApi.delete('/courses/' + courseId)
          const suffix = '?page=1&number=50&company=' + this.$store.getters.companyId + '&sort=desc'
          EducamApi.get('/courses' + suffix).then((data) => {
            if (data.data.detail.total_items > 0) {
              this.courses = data.data.detail._embedded.courses
            } else {
              this.courses = []
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    nextPage (page) {
      const suffix = '?page=' + page + '&number=2&company=' + this.$store.getters.companyId + '&sort=desc'
      EducamApi.get('/courses' + suffix).then((response) => {
        if (response.data.detail.total_items > 0) {
          this.courses = response.data.detail._embedded.courses
          this.rows = response.data.detail.total_items
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  mounted () {
    const suffix = '?page=1&number=50&company=' + this.$store.getters.companyId + '&sort=desc'
    EducamApi.get('/courses' + suffix).then((response) => {
      if (response.data.detail.total_items > 0) {
        this.courses = response.data.detail._embedded.courses
        this.rows = response.data.detail.total_items
      }
    }).catch((err) => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
