<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Bedrijven</h5>
        <div class="ibox-tools">
          <router-link :to="{name: 'CompaniesCreate'}" class="btn btn-primary btn-xs">Bedrijf toevoegen</router-link>
        </div>
      </div>
      <div class="ibox-content">
        <b-table
          striped
          hover
          :items="companies"
          :fields="fields"
          show-empty
          id="companiesTable"
        >
          <template v-slot:empty>
            <b-row>
              <b-col cols="12">
                <h4 class="text-center text-muted">
                  Er zijn geen bedrijven om weer te geven
                </h4>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(id)="row">
            {{ row.item.id }}
          </template>
          <template v-slot:cell(name)="row">
            {{ row.item.name }}
          </template>
          <template v-slot:cell(type)="row">
            {{ row.item.company_type !== null ? row.item.company_type.type : null }}
          </template>
          <template v-slot:cell(address)="row">
            {{ row.item.street }} {{ row.item.number }},<br />
            {{ row.item.postal_code }} {{ row.item.town }},
            {{ row.item.country }}
          </template>
          <template v-slot:cell(actions)="row">
            <div class="ibox-links">
              <b-link :to="{name: 'CompaniesUpdate', params: {id: row.item.id}}" class="btn btn-white btn-bitbucket" ><i class="fa fa-wrench text-info"></i></b-link>
              <b-link  class="btn btn-white btn-bitbucket" @click="showDeleteModal(row.item.id, row.item.name)"><i class="fa fa-trash text-danger"></i></b-link>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Read',
  data () {
    return {
      companies: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Naam', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'vat', label: 'BTW', sortable: true },
        { key: 'address', label: 'Adres', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'actions', label: 'Acties', sortable: false }
      ]
    }
  },
  methods: {
    showDeleteModal (companyId, companyName) {
      const h = this.$createElement
      const messageVNode = h('p', {}, ['Je staat op het punt het bedrijf ', h('strong', {}, companyName), ' te verwijderen.', h('br'), ' Weet je zeker dat je wil doorgaan?'])
      const buttonVNode = h('span', {}, ['Verwijderen ', h('i', { class: 'fa fa-trash' })])
      this.$bvModal.msgBoxConfirm(messageVNode, {
        title: 'Bedrijf verwijderen',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: buttonVNode,
        cancelTitle: 'Annuleren',
        hideHeaderClose: true,
        centered: true
      }).then(async value => {
        if (value === true) {
          await EducamApi.delete('/crm/companies/' + companyId)
          EducamApi.get('/crm/companies').then((data) => {
            if (data.data.detail.total_items > 0) {
              this.companies = data.data.detail._embedded.companies
            }
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  beforeMount () {
    EducamApi.get('/crm/companies').then((response) => {
      if (response.data.detail.total_items > 0) {
        this.companies = response.data.detail._embedded.companies
      }
    }).catch((err) => {
      console.log(err)
    })
  }
}
</script>

<style scoped>

</style>
