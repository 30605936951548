<template>
  <div class="full-width">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'UserRoles'
}
</script>

<style scoped>

</style>
