<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Titel"
        label-for="title"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="title"
          type="text"
          v-model="form.title"
          :state="formValidation.title"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
          Een titel is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Code"
        label-for="code"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="code"
          type="text"
          v-model="form.code"
          :state="formValidation.code"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.code" v-if="formValidation.code === false">
          Een code is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Maximum inschrijvingen"
        label-for="max_students"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="max_students"
          type="number"
          v-model="form.max_students"
          :state="formValidation.max_students"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.max_students" v-if="formValidation.max_students === false">
          Maximum inschrijvingen is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Link"
        label-for="link"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-input
          id="link"
          type="text"
          v-model="form.link"
          :state="formValidation.link"
        ></b-form-input>
        <b-form-invalid-feedback :state="formValidation.link" v-if="formValidation.link === false">
          Link is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Startdatum"
        label-for="starting_date"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-datepicker
          id="starting_date"
          v-model="form.starting_date"
          locale="nl"
          placeholder="Selecteer een startdatum"
          :state="formValidation.starting_date"
        ></b-form-datepicker>
        <b-form-invalid-feedback :state="formValidation.starting_date" v-if="formValidation.starting_date === false">
          Startdatum is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Starttijd"
        label-for="starting_time"
        label-align="left"
        label-align-sm="right"
        label-class="required"
      >
        <b-form-timepicker
          id="starting_time"
          v-model="form.starting_time"
          locale="nl"
          placeholder="Selecteer een starttijd"
          :state="formValidation.starting_time"
        ></b-form-timepicker>
        <b-form-invalid-feedback :state="formValidation.starting_time" v-if="formValidation.starting_time === false">
          Starttijd is verplicht
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Actief"
        label-for="active"
        label-align="left"
        label-align-sm="right"
      >
        <b-form-checkbox
          id="active"
          value="active"
          unchecked-value="inactive"
          v-model="form.active"
        >
          Zichtbaar in overzicht
        </b-form-checkbox>
      </b-form-group>

      <hr class="hr-line-dashed" />
      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Inschrijvingsemail"
        label-for="subscription_email_id"
        label-align="left"
        label-align-sm="right"
      >
        <b-form-select
          id="subscription_email_id"
          type="text"
          :options="this.emails"
          v-model="form.subscription_email_id"
          :state="formValidation.subscription_email_id"
        ></b-form-select>
      </b-form-group>

      <hr class="hr-line-dashed" />
      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Reminder email"
        label-for="reminder_email_id"
        label-align="left"
        label-align-sm="right"
      >
        <b-form-select
          id="reminder_email_id"
          type="text"
          :options="this.emails"
          v-model="form.reminder_email_id"
          :state="formValidation.reminder_email_id"
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Reminder datum"
        label-for="reminder_date"
        label-align="left"
        label-align-sm="right"
      >
        <b-form-datepicker
          id="reminder_date"
          v-model="form.reminder_date"
          locale="nl"
          placeholder="Selecteer een reminder datum"
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group
        label-cols-sm="2"
        content-cols-sm
        label="Reminder tijd"
        label-for="reminder_time"
        label-align="left"
        label-align-sm="right"
      >
        <b-form-timepicker
          id="reminder_time"
          v-model="form.reminder_time"
          locale="nl"
          placeholder="Selecteer een reminder tijd"
        ></b-form-timepicker>
      </b-form-group>
      <hr class="hr-line-dashed" />
      <b-col sm="4" offset-sm="2">
        <b-button type="submit" variant="primary">Opslaan</b-button>
        <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
      </b-col>
    </b-form>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Webinar',
  props: {
    course: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      emails: [{
        value: '',
        text: '----- Selecteer een email -----'
      }],
      form: {
        title: '',
        code: '',
        max_students: 500,
        link: '',
        starting_date: '',
        starting_time: '',
        active: false,
        reminder_email_id: '',
        subscription_email_id: '',
        reminder_date: '',
        reminder_time: ''
      },
      formValidation: {
        title: null,
        code: null,
        max_students: null,
        link: null,
        starting_date: null,
        starting_time: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('mailing/emails?company=' + this.$store.getters.companyId).then(response => {
      response.data.detail._embedded.emails.forEach((value, key) => {
        this.emails.push({
          text: value.title,
          value: value.id
        })
      })
    }).catch(err => {
      console.log(err)
    })

    if (this.course) {
      if (this.course.reminder_date !== '' && this.course.reminder_date !== null) {
        this.form.reminder_date = this.course.reminder_date.date.slice(0, 10)
        this.form.reminder_time = this.course.reminder_date.date.slice(11, 16)
        this.form.title = this.course.title
        this.form.code = this.course.code
        this.form.link = this.course.link
        this.form.starting_date = this.course.starting_date.date.slice(0, 10)
        this.form.starting_time = this.course.starting_date.date.slice(11, 16)
        this.form.max_students = this.course.max_students
        this.form.active = (this.course.active === 1) ? 'active' : null
        this.form.reminder_email_id = this.course.reminder_email_id
        this.form.subscription_email_id = this.course.subscription_email_id
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.title = this.form.title !== ''
      this.formValidation.code = this.form.code !== ''
      this.formValidation.max_students = this.form.max_students !== ''
      this.formValidation.link = this.form.link !== ''
      this.formValidation.starting_date = this.form.starting_date !== ''
      this.formValidation.starting_time = this.form.starting_time !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        this.$emit('formSubmit', this.form)
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = ''
      this.form.code = ''
      this.form.active = false
      this.form.link = ''
      this.form.max_students = 500
      this.form.starting_date = ''
      this.form.starting_time = ''
      this.form.subscription_email_id = ''
    },
    resetErrors () {
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
