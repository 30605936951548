import EducamApi from '@/axios/educam-api.instance'

export default {
  methods: {
    composeEmail: function (mailId, replacers) {
      return new Promise((resolve, reject) => {
        EducamApi.get('/mailing/emails/' + mailId).then(response => {
          let emailContent = response.data.detail.content
          if (replacers.length > 0) {
            replacers.forEach((value, index) => {
              emailContent = emailContent.replace('{%' + value.needle + '%}', value.replacer)
            })
          }
          const finalEmail = response.data.detail.template.content.replace('{%content%}', emailContent)
          resolve({
            subject: response.data.detail.subject,
            body: finalEmail
          })
        }).catch(err => {
          console.log(err)
          resolve(false)
        })
      })
    },
    sendMail: function (toAddress, toName, fromAddress, fromName, subject, body) {
      return new Promise((resolve, reject) => {
        EducamApi.post('/mailing/send', {
          from: fromAddress,
          from_name: fromName,
          to: toAddress,
          to_name: toName,
          subject: subject,
          body: body
        }).then(response => {
          resolve(true)
        }).catch(err => {
          resolve(err)
        })
      })
    }
  }
}
