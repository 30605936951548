<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Standaard opleiding aanduiden</h5>
      </div>
      <div class="ibox-content float-e-margins">
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label="Standaard opleiding"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              v-model="form.default_course"
              :options="courses"
              :aria-describedby="ariaDescribedby"
              name="default_course"
              stacked
            ></b-form-radio-group>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Volgende</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="!this.formValidation.default_course">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Je moet verplicht minstens één opleiding selecteren</b-alert>
              </b-col>
            </b-row>
          </transition>
          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de web component. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'DefaultCourse',
  data () {
    return {
      componentData: null,
      courses: [],
      showError: false,
      form: {
        default_course: ''
      },
      formValidation: {
        default_course: true
      }
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.default_course = this.form.default_course !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        const selectedCourses = []

        this.componentData.courses.forEach((value, key) => {
          selectedCourses.push(value.code)
        })

        EducamApi.post('courses/components', {
          title: this.componentData.title,
          conditions_url: this.componentData.conditions_url,
          css_btn_color: this.componentData.css_btn_color,
          css_btn_bg_color: this.componentData.css_btn_bg_color,
          css_font_family: this.componentData.css_font_family,
          css_font_color: this.componentData.css_font_color,
          default_course: this.form.default_course,
          success_message_nl: this.componentData.success_message_nl,
          success_message_fr: this.componentData.success_message_fr,
          courses: selectedCourses,
          companies_id: this.$store.getters.companyId,
          course_types_id: this.componentData.course_types_id
        }).then(response => {
          this.$router.push({ name: 'ComponentFinish', params: { id: response.data.detail.id } })
        }).catch(err => {
          console.log(err)
          this.showError = true
        })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.default_course = ''
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  },
  async beforeCreate () {
    this.componentData = await this.$store.getters.componentData

    if (this.componentData === null) {
      await this.$router.push({ name: 'ComponentsCreate' })
    }

    this.componentData.courses.forEach((value, key) => {
      this.courses.push({
        value: value.code,
        text: value.label
      })
    })
  }
}
</script>

<style scoped>

</style>
