<template>
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-title">
        <h5>Web component toevoegen <small>Vul alle velden in om een web component toe te voegen</small></h5>
      </div>
      <div class="ibox-content float-e-margins">
        <b-form @submit="onSubmit" @reset="onReset" class="form-horizontal" role="form">
          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Titel"
            label-for="title"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-input
              id="title"
              type="text"
              v-model="form.title"
              :state="formValidation.title"
            ></b-form-input>
            <b-form-invalid-feedback :state="formValidation.title" v-if="formValidation.title === false">
              Een titel is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Opleidingstype"
            label-for="courseType"
            label-align="left"
            label-align-sm="right"
            label-class="required"
          >
            <b-form-select
              id="course_types_id"
              v-model="form.course_types_id"
              :options="courseTypes"
              :state="formValidation.course_types_id"
            ></b-form-select>
            <b-form-invalid-feedback :state="formValidation.course_types_id">
              Opleidingstype is verplicht
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Link algemene voorwaarden"
            label-for="conditions_url"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-input
              id="conditions_url"
              type="text"
              v-model="form.conditions_url"
              :state="formValidation.conditions_url"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Knop tekst kleur"
            label-for="css_btn_color"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-input
              id="css_btn_color"
              type="color"
              v-model="form.css_btn_color"
              :state="formValidation.css_btn_color"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Knop achtergrondkleur"
            label-for="css_btn_color"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-input
              id="css_btn_bg_color"
              type="color"
              v-model="form.css_btn_bg_color"
              :state="formValidation.css_btn_bg_color"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Lettertype"
            label-for="css_font_family"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-input
              id="css_font_family"
              type="text"
              v-model="form.css_font_family"
              :state="formValidation.css_font_family"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Tekst kleur"
            label-for="css_font_color"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-input
              id="css_font_color"
              type="color"
              v-model="form.css_font_color"
              :state="formValidation.css_font_color"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Success bericht NL"
            label-for="success_message_nl"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-textarea
              id="css_font_color"
              v-model="form.success_message_nl"
              :state="formValidation.success_message_nl"
              rows="10"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            label-cols-sm="2"
            content-cols-sm
            label="Success bericht FR"
            label-for="success_message_fr"
            label-align="left"
            label-align-sm="right"
          >
            <b-form-textarea
              id="css_font_color"
              v-model="form.success_message_fr"
              :state="formValidation.success_message_fr"
              rows="10"
            ></b-form-textarea>
          </b-form-group>

          <hr class="hr-line-dashed" />
          <b-col sm="4" offset-sm="2">
            <b-button type="submit" variant="primary">Volgende</b-button>
            <b-button type="reset" class="ml-2" variant="danger">Annuleren</b-button>
          </b-col>

          <transition name="fade">
            <b-row v-if="showError">
              <b-col sm="12">
                <hr class="hr-line-dashed" />
              </b-col>
              <b-col sm="4" offset-sm="2">
                <b-alert variant="danger" show>Er is een serverfout opgetreden bij het opslaan van de web component. Probeer opnieuw.</b-alert>
              </b-col>
            </b-row>
          </transition>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import EducamApi from '@/axios/educam-api.instance'

export default {
  name: 'Create',
  data () {
    return {
      courseTypes: [{ value: '', text: '----- Selecteer een opleidingstype -----' }],
      showError: false,
      form: {
        title: '',
        conditions_url: '',
        css_btn_color: '#000000',
        css_btn_bg_color: '#FFFFFF',
        css_font_family: 'Arial',
        css_font_color: '#000000',
        success_message_nl: '',
        success_message_fr: '',
        course_types_id: ''
      },
      formValidation: {
        title: null,
        course_types_id: null
      }
    }
  },
  beforeMount () {
    EducamApi.get('/courses/types').then(response => {
      response.data.detail._embedded.types.forEach((value, index) => {
        this.courseTypes.push({
          value: value.id,
          text: value.title
        })
      })
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()

      this.resetErrors()

      this.formValidation.title = this.form.title !== ''
      this.formValidation.course_types_id = this.form.course_types_id !== ''

      const validationValues = Object.values(this.formValidation)

      if (validationValues.indexOf(false) < 0) {
        this.$store.dispatch('setComponentData', this.form)
        this.$router.push({ name: 'ComponentCourses' })
      }
    },
    onReset (event) {
      event.preventDefault()
      this.form.title = ''
      this.form.conditions_url = ''
      this.form.css_btn_color = ''
      this.form.css_btn_bg_color = ''
      this.form.css_font_family = ''
      this.form.css_font_color = ''
      this.form.success_message_nl = ''
      this.form.success_message_fr = ''
      this.form.course_types_id = ''
    },
    resetErrors () {
      this.showError = false
      for (const property in this.formValidation) {
        this.formValidation[property] = null
      }
    }
  }
}
</script>

<style scoped>

</style>
